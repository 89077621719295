import React, { useEffect } from "react";
import s from "./Button.module.scss";
import back from "../../icons/back.svg";
import { useNavigate } from "react-router-dom";
const BackButton = () => {
  const navigate = useNavigate()
  function handleBack() {
    navigate(-1);
    console.log("Back button pressed");
  }

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleBack);
    }
    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.offClick(handleBack);
      }

    };
  }, [navigate]);


  return (
    <>
      <button className={s.backBtn} onClick={handleBack}>
        <div className={s.customBorder}>
          <img src={back} alt="back" />
        </div>
      </button>
    </>
  );
};

export default BackButton;
