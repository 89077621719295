import React, { useEffect } from "react";
import s from "./Balance.module.scss";

import titleBg from "./title_bg.svg";
import ton from "../icons/ton.svg";
import BackButton from "./Button/Back";
import TonBalance from "./Button/TonBalance";
import { marketgetInfo } from "../../../http";
import { setAccamulativeAmount } from "../../Store/AccamulativeAmount/actionsAccamulativeAmount";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import WalletButton from "../../Shop/Button/Wallet";
export const Balance = () => {

  const dispatch = useDispatch();
  const accamulativeAmount = useSelector(state => state.accamulativeAmount.AccamulativeAmount);

  const {
    data: marketInfoData,
    isLoading: isInfoLoading,
    error: infoError,
    refetch: refetchInfo,
  } = useQuery({
    queryKey: ['marketInfo'],
    queryFn: marketgetInfo,
  });

  useEffect(() => {
    if (accamulativeAmount == null && marketInfoData) {
      dispatch(setAccamulativeAmount(marketInfoData.electricity_balance));
    }
  }, [marketInfoData, dispatch]);

  useEffect(() => {
    if (marketInfoData == null) {
      refetchInfo();
    }
  }, [marketInfoData, refetchInfo]);



  const balance = marketInfoData?.electricity_balance || accamulativeAmount;

  const walletButtonColors = {
    background: "#C6E7FF",
    shadow: "rgba(59, 178, 229, 0.3)",
  };

  return (
    <div className={s.block}>
      <div className={s.backBtn}>
        <BackButton />
      </div>

      <div className={s.walletBtn}>
      </div>

      <div className={s.topBar}>
        <div className={s.backgroundTrapezoid}>
          <h1 className={s.title}>Your Balance</h1>
          <img src={titleBg} alt="title bg" width="222" height="40" />
        </div>
      </div>

      <div className={s.bottomBar}>
        <div className={s.count}>
          {isInfoLoading ? "..." : <>{balance}<span>kW</span>
          </>}
        </div>

        <div className={s.history}>
          <div className={s.total}>
            {"Supply kW"} <span>{120}K</span>
          </div>
          <div className={s.perDay}>
            {"Daily Volume"}
            <span>{120}K</span>
            <img src={ton} alt="ton" />
          </div>
        </div>
      </div>
    </div>
  );
};
