import React, { useState, useEffect } from "react";
import s from "./Button.module.scss";
import copy from "../../icons/copy.svg";
import { getInviteLink } from "../../../../http";

const CopyLink = () => {
  const [copyStatus, setCopyStatus] = useState(false);
  const [referralLink, setReferralLink] = useState("Fetching...");

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const link = await getInviteLink();
        setReferralLink(link);
      } catch (error) {
        setReferralLink("Error fetching link");
      }
    };
    fetchLink();
  }, []);

  const handleClick = () => {
    navigator.clipboard.writeText(referralLink);
    setCopyStatus(true);
    setTimeout(() => {
      setCopyStatus(false);
    }, 2000);
  };

  return (
    <button className={s.copyLink} onClick={handleClick}>
      <div className={s.customBorder}>
        <span>{!copyStatus ? "Copy link" : "Copied"}</span>
        <img src={copy} alt="copy" />
      </div>
    </button>
  );
};

export default CopyLink;
