import React from "react";
import s from "./Nft.module.scss";
import titleBg from "./title_bg.svg";

import CardList from "./Cards/CardList";
import BackButton from "../Button/Back";
import WalletButton from "../Button/Wallet";

import { useQuery } from '@tanstack/react-query';
import { getEquipmentList } from "../../../http";


const upgradeData = [
  {
    id: 0,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "rare",
  },
  {
    id: 1,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "common",
  },
  {
    id: 2,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "common",
  },
  {
    id: 3,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "rare",
  },
  {
    id: 4,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "Rare",
  },
  {
    id: 5,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "rare",
  },
  {
    id: 6,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "rare",
  },
  {
    id: 7,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "rare",
  },
  {
    id: 8,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "Epic",
  },
  {
    id: 9,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "Epic",
  },
  {
    id: 10,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "rare",
  },
  {
    id: 11,
    title: "ASIC S1",
    power: 6.57,
    speed: 0.52,
    consumes: 1000,
    imageSrc: "",
    price: 16,
    oldPrice: 32,
    type: "rare",
  },
];

const Upgrade = ({ Func }) => {

  const { data, isLoading, error } = useQuery({
    queryKey: ['shopData'],
    queryFn: getEquipmentList,
  });

  const walletButtonColors = {
    background: "#C7FFC6",
    // border: "#3BE5A8",
    shadow: "rgba(59, 229, 188, 0.3)",
  };


  return (
    <div className={s.block}>
      <div className={s.backBtn} onClick={() => Func()}>
        <BackButton />
      </div>
      <div className={s.walletBtn}>
        <WalletButton colors={walletButtonColors} />
      </div>
      <div className={s.topBar}>
        <h2 className={s.title}>ASICs Shop</h2>

        <div className={s.backgroundTrapezoid}>
          <img src={titleBg} alt="title bg" width="222" height="40" />
          {/* <div className={s.stick}>-50%</div> */}
        </div>
      </div>
      <CardList data={data} />
    </div>
  );
};

export default Upgrade;
