import React, { useEffect, useRef } from "react";
import Slot from "./Slot";

import s from "./Equip.module.scss";
import { getUserSlots } from "../../../http";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../QueryCLient";
import { setToSlots } from "../../../http";
import { useTonAddress } from "@tonconnect/ui-react";
// const equipSlots = [
//   {
//     id: 1,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 2,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 3,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 4,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 5,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 6,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 7,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 8,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 9,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
//   {
//     id: 10,
//     title: "Empty Slot",
//     power: null,
//     speed: null,
//     consumes: null,
//     imageSrc: null,
//   },
// ];

const Equip = () => {
  const containerRef = useRef(null);


  const { data: equipSlots, isLoading, error } = useQuery({
    queryKey: ['UserSlots'],
    queryFn: () => getUserSlots(),
  });
  const rawAddress = useTonAddress(false);

  const LinkAsics = async () => {
    console.log(rawAddress)
    await setToSlots(rawAddress)
    queryClient.invalidateQueries({ queryKey: ['UserSlots'] });
    queryClient.invalidateQueries({ queryKey: ['HourlyInfo'] });

  }

  useEffect(() => {
    const preventTouchMove = (e) => e.preventDefault();
    window.addEventListener("touchmove", preventTouchMove, { passive: false });

    const container = containerRef.current;
    const allowTouchMove = (e) => {
      if (container.contains(e.target)) {
        e.stopPropagation();
      }
    };

    if (container) {
      container.addEventListener("touchmove", allowTouchMove, { passive: false });
    }

    return () => {

      window.removeEventListener("touchmove", preventTouchMove, { passive: false });
      if (container) {
        container.removeEventListener("touchmove", allowTouchMove, { passive: false });
      }
    };
  }, []);

  if (isLoading) return 'Loading...';
  if (error) return `An error has occurred: ${error.message}`;



  return (
    <div className={s.container} ref={containerRef}>
      {equipSlots.map((slotItem, index) => {
        return <Slot key={slotItem.id} {...slotItem} />;
      })}

      <div className={s.linkAsics} onClick={LinkAsics}>Link Asics</div>
    </div>
  );
};

export default Equip;
