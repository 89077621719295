import { SET_ELECTRICITY_DATA } from './actions';

const initialState = {
    electricityData: null,
};

function electricityReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ELECTRICITY_DATA:
            return {
                ...state,
                electricityData: action.payload
            };
        default:
            return state;
    }
}

export default electricityReducer;
