import React, { useState } from "react";
import s from "./Market.module.scss";
import { Balance } from "./Balance";
import P2p from "./P2p";
import Order from "./Order";
import Filter from "./Filter";
import Modal from "./Modal";

function Market() {
  const [filterQuery, setFilterQuery] = useState("");
  const [history, setHistory] = useState(false);
  return (
    <div className={s.market}>
      <div className={s.content}>
        <Balance />
        <Order />
        <Filter setFilterQuery={setFilterQuery} history={history} />
        <P2p filterQuery={filterQuery} history={history} setHistory={setHistory} />
      </div>
    </div>
  );
}

export default Market;
