import React, { useState } from "react";
import s from "./Card.module.scss";

const Card = ({ cardData, setModalData, setIsOpen, isOpen }) => {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={s.card}>
      <div className={s.border}>
        <div className={s.topBar}>{cardData.title}</div>
        <div className={s.body}>
          <div className={s.content}>
            <div className={s.value}>
              {cardData.value}
              <span className={s.unit}>{cardData.unit}</span>
            </div>
          </div>
          {cardData.modalType && (
            <button
              className={s.modalButton}
              onClick={() => {
                toggleModal();
                setModalData(cardData);
              }}
            >
              <div className={s.customBorder}>
                <span>
                  {cardData.modalType === "add-energy" && "Add"}
                  {cardData.modalType === "claim" && "Claim"}
                </span>
              </div>
            </button>
          )}

          <div className={s.backgroundImage}>
            {cardData.imageSrc && (
              <img src={cardData.imageSrc} alt="backgroundImage" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
