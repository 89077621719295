export const SET_ACCAMULATIVE_AMOUNT = 'SET_ACCAMULATIVE_AMOUNT';
export const DECREASE_ACCAMULATIVE_AMOUNT = 'DECREASE_ACCAMULATIVE_AMOUNT';
export const INCREASE_ACCAMULATIVE_AMOUNT = 'INCREASE_ACCAMULATIVE_AMOUNT';

export function setAccamulativeAmount(value) {
  console.log("Setting electricity count:", value); 
  return {
    type: SET_ACCAMULATIVE_AMOUNT,
    payload: value,
  };
}

export function decreaseAccamulativeAmount(amount) {
  return {
    type: DECREASE_ACCAMULATIVE_AMOUNT,
    payload: amount,
  };
}
export function increaseAccamulativeAmount(amount) {
  return {
    type: INCREASE_ACCAMULATIVE_AMOUNT,
    payload: amount,
  };
}
