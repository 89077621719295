import React, { useEffect, useState, useRef } from "react";
import Header from "../UI/Header";
import "./miner.scss";
import Footer from "../UI/Footer";
import { getMinerInfo } from "../../http";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import acis from "./Images/ASIC.png";

import CardList from "./Cards/CardList";

function MinerBlock({ name, icon, value, path }) {
  const navigate = useNavigate();
  return (
    <div className="miner_block" onClick={() => navigate(path)}>
      <div className="text">{name}</div>
      <div className="miner_container">
        <div style={{ width: "100%" }}>
          <img src={icon} style={{ width: "100%" }}></img>
        </div>
        <div className="subtext">{value}</div>
      </div>
    </div>
  );
}

function Miner() {
  const containerRef = useRef(null);

  const { data, isPending, error } = useQuery({
    queryKey: ["minerData"],
    queryFn: () => getMinerInfo(),
  });

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      if (
        window.Telegram.WebApp.BackButton &&
        window.Telegram.WebApp.BackButton.isVisible
      ) {
        window.Telegram.WebApp.BackButton.hide();
      }
    }
  }, []);

  useEffect(() => {
    const preventTouchMove = (e) => e.preventDefault();
    window.addEventListener("touchmove", preventTouchMove, { passive: false });

    const container = containerRef.current;
    const allowTouchMove = (e) => {
      if (container.contains(e.target)) {
        e.stopPropagation();
      }
    };

    if (container) {
      container.addEventListener("touchmove", allowTouchMove, { passive: false });
    }

    return () => {

      window.removeEventListener("touchmove", preventTouchMove, { passive: false });
      if (container) {
        container.removeEventListener("touchmove", allowTouchMove, { passive: false });
      }
    };
  }, []);


  if (isPending) return "Loading...";
  if (error) return `An error has occurred: ${error.message}`;
  console.log(data);

  const minerCards = [
    {
      id: 0,
      title: "ASICs",
      value: `${data.count}/${data.asic_slots}`,
      unit: "",
      path: "/game",
      imageSrc: acis,
    },
    {
      id: 1,
      title: "Mining Power",
      value: data.mining_power,
      unit: " Mh/s",
      path: "",
      imageSrc:
        "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Miner/Mining+Power.webp",
    },
    {
      id: 2,
      title: "ACCUMULATED ENERGY",
      value: data.accumulated_energy,
      unit: " kW",
      path: "",
      imageSrc: "https://power-stations-bucket.s3.eu-north-1.amazonaws.com/accamulated.png",
      modalType: "add-energy",
    },
    {
      id: 3,
      title: "MINED tBTC",
      value: data.coins,
      unit: " tBTC",
      path: "",
      imageSrc: "https://power-stations-bucket.s3.eu-north-1.amazonaws.com/mined.png",
      modalType: "claim",
    },
    {
      id: 4,
      title: "Power Consumption",
      value: data.power_consumption,
      unit: " Kw/h",
      path: "",
      imageSrc:
        "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Miner/Power+Consumption.webp",
    },
    {
      id: 5,
      title: "Mining Speed",
      value: data.mining_speed,
      unit: " tBTC/d",
      path: "",
      imageSrc:
        "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Miner/Mining+Speed.webp",
    },
    {
      id: 6,
      title: "Energy left",
      value: data.energy_left,
      unit: " Hours",
      path: "",
      imageSrc:
        "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Miner/Energy+Left.webp",
    },
    {
      id: 7,
      title: "Boost",
      value: "150",
      unit: "%",
      path: "",
      imageSrc:
        "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Miner/Boost.webp",
    },
    {
      id: 8,
      title: "Co-miners",
      value: "",
      unit: "",
      path: "",
      imageSrc:
        "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Miner/Co-miners.webp",
    },
    {
      id: 9,
      title: "Rewards",
      value: "",
      unit: "",
      path: "",
      imageSrc:
        "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/Miner/Rewards.webp",
    },
  ];

  return (
    <div className="miner">
      <Header />

      <div className="wrapper" ref={containerRef}>
        <CardList minerCards={minerCards} />
      </div>

      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Miner;
