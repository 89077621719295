import React from "react";
import s from "./Button.module.scss";
import plus from "../../images/plus.svg";

const AddButton = () => {
  return (
    <button className={s.plusButton}>
      <div>BUY</div>
      <div>ASIC</div>
    </button>
  );
};

export default AddButton;
