import React, { useState, useEffect, useRef } from "react";
import s from "./Friends.module.scss";

import { Balance } from "./Balance";
import Bonuses from "./Bonuses";
import { getFriendsList, getFriendsInfo } from "../../http";
import { useQuery } from "@tanstack/react-query";
import GetBonuses from "./GetBonuses";
import Filter from "./Filter/Filter";
import { throttle } from 'lodash';

function Friends() {
  const [filterQuery, setFilterQuery] = useState("day");
  const containerRef = useRef(null);
  useEffect(() => {
    const preventDefault = (e) => {
      if (containerRef.current && containerRef.current.contains(e.target)) {
        return;
      }
      e.preventDefault();
    };

    document.addEventListener("touchmove", preventDefault, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventDefault);
    };
  }, []);

  const { data: referralsData, error: referralsError, isLoading: referralsIsLoading } = useQuery({
    queryKey: ['referrals', filterQuery],
    queryFn: () => getFriendsList(filterQuery),
    keepPreviousData: true,
  });


  const { data: friendsInfo, error: friendsInfoError, isLoading: friendsInfoIsLoading } = useQuery({
    queryKey: ['friendsInfo'],
    queryFn: getFriendsInfo,
  });
  if (friendsInfoIsLoading) {
    return <div>Loading...</div>;
  }

  if (referralsError || friendsInfoError) {
    return <div>Error loading data: {referralsError?.message || friendsInfoError?.message}</div>;
  }

  return (
    <div className={s.friends} >
      <div className={s.content} ref={containerRef}>
        <Balance count={friendsInfo?.friends_count} />
        <GetBonuses friendsInfo={friendsInfo} />
        <div>
          <Filter setFilterQuery={setFilterQuery} />
          <Bonuses referrals={referralsData} referralsIsLoading={referralsIsLoading} />
        </div>

      </div>
    </div>
  );
}

export default Friends;
