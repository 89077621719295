
const URL = "https://tonbitcoin.cc/";
// const URL = "https://ae57-84-54-153-201.ngrok-free.app/";
// const URL = "https://f3babcc2a9ad.ngrok.app/";

let USER_ID = 5928954497
// let USER_ID = 12648076270

// const initData = window.Telegram.WebApp.initData;
// const params = new URLSearchParams(initData);
// const hash = params.get('hash');


const hash = "vitalikkrasavchik";
// let USER_ID = null

// if (window.Telegram && window.Telegram.WebApp) {
//   window.Telegram.WebApp.ready();

//   if (window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
//     USER_ID = window.Telegram.WebApp.initDataUnsafe.user.id;
//   } else {
//     console.error('Telegram user data is not available.');
//   }
// } 
// else {
//   console.error('Telegram WebApp is not initialized.');
// }

const fetchData = async (request) => {
  try {
    const response = await fetch(`${URL + request}`, {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "1",
        "Authorization": `Bearer ${hash}`,

      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log('data:', request, data);
    return data;
  } catch (error) {
    return null;
  }
};

const sendData = async (request, data) => {
  try {
    const formattedRequest = request.endsWith('/') ? request : `${request}`;
    console.log(JSON.stringify(data))
    const formData = new URLSearchParams(data).toString();

    const response = await fetch(`${URL}${formattedRequest}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${hash}`,

      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log('post:', request, result);
    return result;
  } catch (error) {
    console.error('Error fetching:', error);
    return null;
  }
};

const putData = async (request, data) => {
  try {
    const formattedRequest = request.endsWith('/') ? request : `${request}`;
    console.log(data)
    const response = await fetch(`${URL}${formattedRequest}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${hash}`,

      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log('put:', request, result);
    return result;
  } catch (error) {
    console.error('Error fetching:', error);
    return null;
  }
};

const patchData = async (request, data) => {
  try {
    const formattedRequest = request.endsWith('/') ? request : `${request}`;
    console.log(data)
    const response = await fetch(`${URL}${formattedRequest}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${hash}`,

      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log('PATCH:', request, result);
    return result;
  } catch (error) {
    console.error('Error fetching:', error);
    return null;
  }
};

export const Authorization = async (init_data) => {
  const response = await sendData("user/auth", {init_data: init_data});
  return response;
}; 

export const postOffer = async (amount, price,creator_address) => {
  const response = await sendData("market/postOffer", {creator_id: USER_ID, amount: amount, price: price, creator_address : creator_address});
  return response;
}; 

export const unlockTransaction = async (offer_id,transaction_mongo_id) => {
  const response = await sendData("market/unlock_transaction_new", {offer_id: offer_id,transaction_mongo_id :transaction_mongo_id} );
  return response;
};

export const confirmTransaction = async (offer_id, boc,comment,wallet_address) => {
  const response = await sendData(`market/confirm_transaction_new`, {offer_id: offer_id, transaction_boc: boc, user_id: USER_ID, comment:comment, wallet_address:wallet_address} );
  return response;
};
export const transferElectricity = async (amount) => {
  const response = await putData(`miner/transferElectricity`, {user_id: USER_ID, amount: amount});
  return response;
};
export const removeFromSlot = async (id) => {
  const response = await putData(`miner/removeFromSlot`, {id:id});
  return response;
};

export const updateElectricityCount = async (id, electricity_added) => {
  const response = await putData("energizer/handle_tap", { "electricity_added": electricity_added, "id": id })
  return response;
}

export const HireEngineer = async (id) => {
  const response = await putData("energizer/hire_engineer", { "id": id })
  return response;
}

export const UpgradeGeneration = async (id) => {
  const response = await putData("energizer/upgrade_generation", { "id": id })
  return response;
}

export const UpgradeStation = async (id, upgrade_type) => {
  const response = await putData("energizer/upgrade_station", { "id": id, "upgrade_type": upgrade_type })
  return response;
}
export const closeOffer = async (id) => {
  const response = await putData("market/closeOffer", { "id": id})
  return response;
}

export const setToSlots = async (address) => {
  const response = await putData("miner/setToSlots", { "user_id": USER_ID, "address":address})
  return response;
}
export const processTransaction = async (id,wallet_address) => {
  const response = await patchData("market/processTransaction", { "id": id,"wallet_address":wallet_address, "buyer_id":USER_ID})
  return response;
}


export const getInfo = async () => {
  const response = await fetchData("energizer/getInfo" + "?user_id=" + USER_ID)
  return response;
}

export const getUpdates = async () => {
  const response = await fetchData("energizer/getUpdates" + "?user_id=" + USER_ID)
  return response;
}
export const marketgetInfo = async () => {
  const response = await fetchData(`market/getInfo/${USER_ID}` )
  return response;
}

export const getMinerInfo = async () => {
  const response = await fetchData("miner/getInfo" + "?user_id=" + USER_ID)
  return response;
}

export const getEquipmentList = async () => {
  const response = await fetchData("miner/equipment_list" + "?user_id=" + USER_ID)
  return response;
}

export const getReferrals = async () => {
  const response = await fetchData("energizer/getReferrals" + "?user_id=" + USER_ID)
  return response;
}

export const getHourlyInfo = async () => {
  const response = await fetchData("energizer/getHourlyInfo" + "?user_id=" + USER_ID)
  return response;
}

export const getOffers = async (filterQuery) => {
  const response = await fetchData(`market/getOffers/${USER_ID}${filterQuery}`);
  return response;
}
export const getHistory = async (filterQuery) => {
  const response = await fetchData(`market/getHistory/${USER_ID}${filterQuery}`);
  return response;
}

export const getElectricityCount = async () => {
  const response = await fetchData("energizer/getElectricityCount" + "?user_id=" + USER_ID)
  return response;
}

export const getUserSlots = async () => {
  const response = await fetchData("miner/user_slots" + "?user_id=" + USER_ID)
  return response;
}
export const getFriendsList = async (filter) => {
  const response = await fetchData(`friends/friends_list?filter=${encodeURIComponent(filter)}`);
  return response;
}
export const getFriendsInfo = async () => {
  const response = await fetchData("friends/getInfo")
  return response;
}
export const claimEnergizerProfit = async () => {
  const response = await patchData("friends/claim_energizer_profit")
  return response;
}
export const claimMinerProfit = async () => {
  const response = await patchData("friends/claim_miner_profit")
  return response;
}
export const getInviteLink = async () => {
  const response = await fetchData("friends/getInviteLink" + "?user_id=" + USER_ID)
  return response;
}