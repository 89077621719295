import React, { useEffect, useRef } from "react";
import Equip from "./Equip";
import s from "./Game.module.scss";
import Footer from "../UI/Footer";
import { useNavigate } from "react-router-dom";
import Header from "../UI/Header";

const Game = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);

  function handleBack() {
    navigate(-1);
    console.log("Back button pressed");
  }

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleBack);
    }

    const preventTouchMove = (e) => e.preventDefault();
    window.addEventListener("touchmove", preventTouchMove, { passive: false });

    const container = containerRef.current;
    const allowTouchMove = (e) => {
      if (container.contains(e.target)) {
        e.stopPropagation();
      }
    };

    if (container) {
      container.addEventListener("touchmove", allowTouchMove, { passive: false });
    }

    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.offClick(handleBack);
      }
      window.removeEventListener("touchmove", preventTouchMove, { passive: false });
      if (container) {
        container.removeEventListener("touchmove", allowTouchMove, { passive: false });
      }
    };
  }, [navigate]);

  return (
    <div className={s.miner}>
      <Header />
      <div className={s.container} ref={containerRef}>
        <Equip />
      </div>
      <div className={s.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Game;
