import React from "react";
import s from "./Button.module.scss";
import ton from "../icons/ton.svg";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { beginCell } from "@ton/core";
const BuyButton = ({ link, price, id }) => {
  const handleClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(link);
    } else {
      window.open(link, "_blank");
    }
  };


  const [tonConnectUI] = useTonConnectUI();

  const handleConfirm = async () => {
    const body = beginCell().storeUint(1, 32).storeUint(1, 64).storeUint((id - 1), 4).endCell()

    const myTransaction = {
      validUntil: Math.floor(Date.now() / 1000) + 360,
      messages: [
        {
          address: "EQAGKlyJq1BJ0h-ACkt9fNH3OYpNZNhcg8GxVvLw6ESy2C2n",
          amount: price * 1e9 + 0.1 * 1e9,
          payload: body.toBoc().toString("base64")
        }
      ]
    }
    const txResponse = await tonConnectUI.sendTransaction(myTransaction);
  }

  return (
    link == null ? (
      <button className={s.soldout}>
        <div className={s.customBorder}>
          <span>Sold out</span>
        </div>
      </button>
    ) : (
      <button className={s.buyButton} onClick={link.length > 5 ? handleClick : handleConfirm}>
        <div className={s.customBorder}>
          <span>Buy now</span>
          <img src={ton} alt="buy" />
        </div>
      </button>
    )
  );
};

export default BuyButton;
