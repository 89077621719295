import React from "react";
import s from "./Slot.module.scss";

import background_image from "../../images/background.svg";
import bg_slot_full from "../../images/slot_full.jpg";
import bg_slot_empty from "./empty.png";
import AddButton from "../Button/Add";
import RemoveButton from "../Button/Remove";
import { useNavigate } from "react-router-dom";
import { removeFromSlot } from "../../../../http";
import { queryClient } from "../../../../QueryCLient";

const EmptySlot = ({ title, imageSrc }) => {
  const navigate = useNavigate();
  return (
    <div className={s.slot} onClick={() => navigate("/shop")}>
      <h2 className={s.title}>Empty slot</h2>
      <div className={s.description}>
        <img
          className={s.backgroundImageEmpty}
          src={imageSrc || bg_slot_empty}
          alt={title}
        />
        <div className={s.addButton}>
          <AddButton />
        </div>
      </div>
      <img className={s.background} src={background_image} alt="bg" />
    </div>
  );
};

const AsicSlot = ({ name, power, mining, consumption, image, id }) => {
  const remove = async () => {
    await removeFromSlot(id);
    queryClient.invalidateQueries({ queryKey: ['UserSlots'] });
    queryClient.invalidateQueries({ queryKey: ['HourlyInfo'] });

  };

  return (
    <div className={s.slot}>
      <h2 className={s.title}>{name}</h2>
      <div className={s.removeButton}>
        <RemoveButton Func={remove} />
      </div>
      <div className={s.description}>
        <img
          className={s.backgroundImageFull}
          src={image || bg_slot_full}
          alt={name}
        />
        <p className={s.descriptionItem}>
          Power <span className={s.value}>{power}</span>{" "}
          <span className={s.unit}>H/s</span>
        </p>
        <p className={s.descriptionItem}>
          Speed <span className={s.value}>{mining}</span>{" "}
          <span className={s.unit}>tBTC/d</span>
        </p>
        <p className={s.descriptionItem}>
          Consumes <span className={s.value}>{consumption}</span>{" "}
          <span className={s.unit}>Kw/h</span>
        </p>
      </div>
      <img className={s.background} src={background_image} alt="bg" />
    </div>
  );
};

const Slot = (props) => {
  const isEmpty = props.equipment === null;
  return isEmpty ? <EmptySlot {...props} /> : <AsicSlot {...props.equipment} id={props.id} />;
};

export default Slot;
