
import React, { useEffect, useRef } from "react";
import s from "./Bonuses.module.scss";
import titleBg from "./title_bg.svg";
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person2';
import logo from "./logo.png";

const Bonuses = ({ referrals, referralsIsLoading }) => {
  const containerRef = useRef(null);
  const refferals = [
    {
      "friend_id": 2646248239,
      "friend_name": "User1",
      "first_level_refs_energy": 0.418,
      "second_level_refs_energy": 1.133,
      "first_level_refs_coins": 0.814,
      "second_level_refs_coins": 0.806,
      "asics_count": 3
    },
    {
      "friend_id": 8021061056,
      "friend_name": "User2",
      "first_level_refs_energy": 0.323,
      "second_level_refs_energy": 0.531,
      "first_level_refs_coins": 0.397,
      "second_level_refs_coins": 0.704,
      "asics_count": 2
    },
    {
      "friend_id": 7073152979,
      "friend_name": "User3",
      "first_level_refs_energy": 1.306,
      "second_level_refs_energy": 1.046,
      "first_level_refs_coins": 0.881,
      "second_level_refs_coins": 0.1,
      "asics_count": 0
    },
    {
      "friend_id": 6045874162,
      "friend_name": "User4",
      "first_level_refs_energy": 0.507,
      "second_level_refs_energy": 0.188,
      "first_level_refs_coins": 0.581,
      "second_level_refs_coins": 0.919,
      "asics_count": 0
    },
    {
      "friend_id": 1377901399,
      "friend_name": "User5",
      "first_level_refs_energy": 0.933,
      "second_level_refs_energy": 1.152,
      "first_level_refs_coins": 0.882,
      "second_level_refs_coins": 0.714,
      "asics_count": 1
    },
    {
      "friend_id": 8933514968,
      "friend_name": "User6",
      "first_level_refs_energy": 0.489,
      "second_level_refs_energy": 1.859,
      "first_level_refs_coins": 0.693,
      "second_level_refs_coins": 0.999,
      "asics_count": 1
    },
    {
      "friend_id": 1271049868,
      "friend_name": "User7",
      "first_level_refs_energy": 0.318,
      "second_level_refs_energy": 0.637,
      "first_level_refs_coins": 0.725,
      "second_level_refs_coins": 0.149,
      "asics_count": 0
    },
    {
      "friend_id": 1786997841,
      "friend_name": "User8",
      "first_level_refs_energy": 0.221,
      "second_level_refs_energy": 1.335,
      "first_level_refs_coins": 0.501,
      "second_level_refs_coins": 0.868,
      "asics_count": 3
    },
    {
      "friend_id": 7985898265,
      "friend_name": "User9",
      "first_level_refs_energy": 1.313,
      "second_level_refs_energy": 0.264,
      "first_level_refs_coins": 0.956,
      "second_level_refs_coins": 0.162,
      "asics_count": 0
    },
    {
      "friend_id": 5844072776,
      "friend_name": "User10",
      "first_level_refs_energy": 0.276,
      "second_level_refs_energy": 1.433,
      "first_level_refs_coins": 0.644,
      "second_level_refs_coins": 0.616,
      "asics_count": 3
    },
    {
      "friend_id": 7178844308,
      "friend_name": "User11",
      "first_level_refs_energy": 0.393,
      "second_level_refs_energy": 0.579,
      "first_level_refs_coins": 0.424,
      "second_level_refs_coins": 0.124,
      "asics_count": 0
    },
    {
      "friend_id": 9292736591,
      "friend_name": "User12",
      "first_level_refs_energy": 0.737,
      "second_level_refs_energy": 0.366,
      "first_level_refs_coins": 0.606,
      "second_level_refs_coins": 0.848,
      "asics_count": 4
    },
    {
      "friend_id": 3539885489,
      "friend_name": "User13",
      "first_level_refs_energy": 1.642,
      "second_level_refs_energy": 1.173,
      "first_level_refs_coins": 0.019,
      "second_level_refs_coins": 0.807,
      "asics_count": 4
    },
    {
      "friend_id": 2599755969,
      "friend_name": "User14",
      "first_level_refs_energy": 0.194,
      "second_level_refs_energy": 0.04,
      "first_level_refs_coins": 0.302,
      "second_level_refs_coins": 0.569,
      "asics_count": 0
    },
    {
      "friend_id": 6702967737,
      "friend_name": "User15",
      "first_level_refs_energy": 1.676,
      "second_level_refs_energy": 1.658,
      "first_level_refs_coins": 0.66,
      "second_level_refs_coins": 0.407,
      "asics_count": 1
    },
    {
      "friend_id": 2134098327,
      "friend_name": "User16",
      "first_level_refs_energy": 0.192,
      "second_level_refs_energy": 0.009,
      "first_level_refs_coins": 0.29,
      "second_level_refs_coins": 0.069,
      "asics_count": 3
    },
    {
      "friend_id": 9270692224,
      "friend_name": "User17",
      "first_level_refs_energy": 1.953,
      "second_level_refs_energy": 1.356,
      "first_level_refs_coins": 0.618,
      "second_level_refs_coins": 0.697,
      "asics_count": 4
    },
    {
      "friend_id": 1492175012,
      "friend_name": "User18",
      "first_level_refs_energy": 0.937,
      "second_level_refs_energy": 0.54,
      "first_level_refs_coins": 0.429,
      "second_level_refs_coins": 0.454,
      "asics_count": 3
    },
    {
      "friend_id": 5037815941,
      "friend_name": "User19",
      "first_level_refs_energy": 1.954,
      "second_level_refs_energy": 1.47,
      "first_level_refs_coins": 0.135,
      "second_level_refs_coins": 0.722,
      "asics_count": 2
    },
    {
      "friend_id": 9644797672,
      "friend_name": "User20",
      "first_level_refs_energy": 1.21,
      "second_level_refs_energy": 1.924,
      "first_level_refs_coins": 0.298,
      "second_level_refs_coins": 0.866,
      "asics_count": 1
    },
    {
      "friend_id": 1759957521,
      "friend_name": "User21",
      "first_level_refs_energy": 1.479,
      "second_level_refs_energy": 0.498,
      "first_level_refs_coins": 0.57,
      "second_level_refs_coins": 0.976,
      "asics_count": 1
    },
    {
      "friend_id": 6327487786,
      "friend_name": "User22",
      "first_level_refs_energy": 0.078,
      "second_level_refs_energy": 1.152,
      "first_level_refs_coins": 0.591,
      "second_level_refs_coins": 0.856,
      "asics_count": 2
    },
    {
      "friend_id": 3975557633,
      "friend_name": "User23",
      "first_level_refs_energy": 0.566,
      "second_level_refs_energy": 1.184,
      "first_level_refs_coins": 0.574,
      "second_level_refs_coins": 0.012,
      "asics_count": 1
    },
    {
      "friend_id": 4821373159,
      "friend_name": "User24",
      "first_level_refs_energy": 0.24,
      "second_level_refs_energy": 1.145,
      "first_level_refs_coins": 0.653,
      "second_level_refs_coins": 0.36,
      "asics_count": 4
    }]
  const truncateName = (name) => {
    return name.length > 5 ? `${name.slice(0, 5)}...` : name;
  };

  const formatNumber = (num) => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  };

  if (referralsIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={s.block} ref={containerRef}>
      <div className={s.topBar}>
        <h2 className={s.title}>Friends List</h2>
        <div className={s.backgroundTrapezoid}>
          <img src={titleBg} alt="title bg" width="222" height="40" />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.headerContainer}>{"Name"}</div>
        <div className={s.headerContainer}>
          <div className={s.title}>{"kW"}</div>
          <div className={s.profitPersent}>
            <PersonIcon className={s.icon} /> 10% <GroupIcon className={s.icon} /> 5%
          </div>
          <div className={s.subtext}>{"rewards"}</div>
        </div>
        <div className={s.headerContainer}>
          <div className={s.title}>{"tBTC"}</div>
          <div className={s.profitPersent}>
            <PersonIcon className={s.icon} /> 6% <GroupIcon className={s.icon} /> 3%
          </div>
          <div className={s.subtext}>{"rewards"}</div>
        </div>
        <div className={s.headerContainer}>{"Asics"}</div>
      </div>
      <div className={s.referrals}>
        {refferals.map((referral, index) => (
          <div className={s.referralRow} key={referral.friend_id}>
            <div className={s.referralCell}>
              {index + 1}
              <img src={logo} alt="logo" style={{ margin: "-5%" }}></img>
              {truncateName(referral.friend_name)}
            </div>
            <div className={s.referralCell}>
              <div className={s.flex}>
                <PersonIcon className={s.icon} />
                +{formatNumber(referral.first_level_refs_energy)}
              </div>
              <div className={s.flex}>
                <GroupIcon className={s.icon} />
                +{formatNumber(referral.second_level_refs_energy)}
              </div>
            </div>
            <div className={s.referralCell}>
              <div className={s.flex}>
                <PersonIcon className={s.icon} />
                +{formatNumber(referral.first_level_refs_coins)}
              </div>
              <div className={s.flex}>
                <GroupIcon className={s.icon} />
                +{formatNumber(referral.second_level_refs_coins)}
              </div>
            </div>
            <div className={s.referralCell}>{formatNumber(referral.asics_count)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bonuses;
