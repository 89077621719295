import React from "react";

import s from "./InfoBar.module.scss";

const InfoBar = ({ title, indicatorName, indicatorValue }) => {
  return (
    <div className={s.block}>
      <div className={s.title}>{title}</div>
      <div className={s.bar}>
        <div className={s.legendLeft}>{indicatorName}</div>
        <div className={s.progress}>
          <div
            className={s.progressFill}
            style={{ width: `${indicatorValue}%` }}
          ></div>
        </div>
        <div className={s.legendRight}>{indicatorValue}%</div>
      </div>
    </div>
  );
};

export default InfoBar;
