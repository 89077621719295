import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import energizer from "../Navigation/Images/energizer.png";
import investor from "../Navigation/Images/investor.png";
import miner from "../Navigation/Images/miner.png";
import bitcoin from "../Navigation/Images/bitcoin.png";
import user from "../Navigation/Images/user.png";
import wallet from "../Navigation/Images/wallet.png";
import play from "../Navigation/Images/Play.png";
import { TonConnectButton } from '@tonconnect/ui-react';
import "../Navigation/navigation.scss";
import "./header.scss";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import WalletButton from '../Shop/Button/Wallet';
import { useQuery } from '@tanstack/react-query';
import { getHourlyInfo } from '../../http';
import power from "./power.png"

function NavigationBtn({ name, icon, subtext, onClick, colors, isSelected }) {
  const buttonStyle = {
    border: `1px solid ${colors.border}`,
    boxShadow: `0px 0px 6px 5px ${colors.shadow}`,
    color: colors.text,
    width: isSelected ? `37%` : `28%`
  };

  return (
    <div className="navigation_button" style={buttonStyle} onClick={onClick}>
      {isSelected ?
        <svg width="26" height="4" viewBox="0 0 26 4" fill={colors.text} xmlns="http://www.w3.org/2000/svg">
          <path d="M19.1069 3.93443H6.42883C5.56353 3.93443 4.78188 3.50973 4.44301 2.85547C3.87444 1.75771 2.79147 0.894567 1.43882 0.461092L0 0H25.5357L24.0969 0.461091C22.7442 0.894567 21.6613 1.75771 21.0927 2.85547C20.7538 3.50973 19.9722 3.93443 19.1069 3.93443Z" />
        </svg>
        : null
      }

      <div className='text_container_info'>
        <div className='text' style={{ color: colors.text }}>{name}</div>
        <div className='text_container'>
          <img src={icon} alt={`${name} icon`} />
          <div className='subtext' style={{ color: colors.text }}> {subtext}</div>
        </div>
      </div>
      <div className='shadow_top' style={{ boxShadow: `0px 20px 20px 10px ${colors.shadowTop}` }}></div>
      <div className='shadow_bottom' style={{ boxShadow: `0px -20px 20px 20px ${colors.shadowBottom}` }}></div>
    </div>
  );
}

function Header() {
  const [selected, setSelected] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState("");
  const [username, setUsername] = useState("");
  const { data, isLoading, error } = useQuery({
    queryKey: ['HourlyInfo'],
    queryFn: getHourlyInfo,
  });

  useEffect(() => {
    setShow(location.pathname.slice(1));
  }, [location.pathname]);

  const handleClick = (index, path) => {
    setSelected(index);
    navigate(path);
  };

  const navigationData = [
    {
      title: "ENERGIZER",
      icon: energizer,
      subtext: data ? data.electricity_generation + " kW/h" : "Loading...",
      color: {
        text: "#C6E7FF",
        border: "#3BB2E5",
        shadow: "rgba(59, 178, 229, 0.3)",
        shadowTop: "rgba(59, 178, 229, 0.6)",
        shadowBottom: "rgba(59, 178, 229, 0.6)"
      },
      path: "/energizer",
      show: show
    },
    {
      title: "MINER",
      icon: miner,
      subtext: data ? data.mining_speed + " tBTC/h" : "Loading...",
      color: {
        text: "#C7FFC6",
        border: "#3BE5A8",
        shadow: "rgba(59, 229, 188, 0.3)",
        shadowTop: "rgba(59, 229, 188, 0.6)",
        shadowBottom: "rgba(59, 229, 188, 0.6)"
      },
      path: "/miner",
      show: show
    },
    {
      title: "INVESTOR",
      icon: investor,
      subtext: "0 tBTC/h",
      color: {
        text: "#FFF9C6",
        border: "#E0C745",
        shadow: "rgba(229, 212, 59, 0.3)",
        shadowTop: "rgba(229, 212, 59, 0.6)",
        shadowBottom: "rgba(229, 212, 59, 0.6)"
      },
      path: "/investor",
      show: show
    }
  ];

  const truncateUsername = (username) => {
    return username.length > 6 ? `${username.slice(0, 6)}...` : username;
  };

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webAppUser = window.Telegram.WebApp.initDataUnsafe.user;
      if (webAppUser) {
        setUsername(webAppUser.first_name);
      }
    }
  }, []);

  const currentPath = location.pathname;
  let walletButtonColors;
  switch (currentPath) {
    case '/energizer':
      walletButtonColors = {
        background: "#C6E7FF",
        shadow: "rgba(59, 178, 229, 0.3)",
      };
      break;
    case '/miner':
      walletButtonColors = {
        background: "#C7FFC6",
        shadow: "rgba(59, 229, 188, 0.3)",
      };
      break;
    case '/game':
      walletButtonColors = {
        background: "#C7FFC6",
        shadow: "rgba(59, 229, 188, 0.3)",
      };
      break;
    case '/investor':
      walletButtonColors = {
        background: "#FFF9C6",
        shadow: "rgba(229, 212, 59, 0.3)",
      };
      break;
    default:
      walletButtonColors = {
        background: "#FFFFFF",
        border: "#000000",
        shadow: "rgba(0, 0, 0, 0.3)",
      };
  }

  if (isLoading) return 'Loading...';
  if (error) return 'Error loading data';

  return (
    <div>
      <div className='navigation p10_5'>
        {navigationData.map((item, index) => (
          <NavigationBtn
            key={index}
            name={item.title}
            icon={item.icon}
            subtext={item.subtext}
            onClick={() => handleClick(index, item.path)}
            colors={item.color}
            isSelected={location.pathname === item.path || (location.pathname === '/game' && item.path === '/miner')}
          />
        ))}
      </div>

      <div className='block_container p10_5'>
        <div className={'block block-' + show} style={{ width: "65%" }}>

          {location.pathname === '/game' ? (
            <div className='start-mining-block'>
              <img src={power}></img> START MINING
            </div>
          ) : (
            <>
              <div className='info_container'>
                <img src={user} alt="User Icon" />
                <div className='text'> {truncateUsername(username)}</div>
              </div>
              <div className='info_container'>
                <img src={bitcoin} alt="Bitcoin Icon" />
                <div className='text'>Nathan</div>
                <img src={play} alt="play Icon" />
              </div>
            </>
          )}
        </div>
        <div className={'block block-' + show}>
          <WalletButton colors={walletButtonColors} />
        </div>
      </div>
    </div>
  );
}

export default Header;
