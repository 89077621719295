import React from "react";
import s from "./Button.module.scss";
import remove from "../../images/remove.svg";

const RemoveButton = ({ Func }) => {
  return (
    <button className={s.removeButton} onClick={Func}>
      <img src={remove} alt="remove btn" />
    </button>
  );
};

export default RemoveButton;
