import React, { useEffect, useState } from "react";
import s from "./Order.module.scss";
import titleBg from "./title_bg.svg";
import Modal from "../Modal";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { marketgetInfo } from "../../../http";
import { setAccamulativeAmount } from "../../Store/AccamulativeAmount/actionsAccamulativeAmount";

const Order = () => {
  const accamulativeAmount = useSelector(
    (state) => state.accamulativeAmount.AccamulativeAmount
  );

  const [amount, setAmount] = useState();
  // const [total, setTotal] = useState(0);
  const [price, setPrice] = useState();
  const [gasFee, setGasFee] = useState(0.1);

  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useDispatch();

  const {
    data: marketInfoData,
    isLoading: isInfoLoading,
    error: infoError,
    refetch: refetchInfo,
  } = useQuery({
    queryKey: ["marketInfo"],
    queryFn: marketgetInfo,
    enabled: false,
  });

  useEffect(() => {
    if (accamulativeAmount == null && marketInfoData) {
      dispatch(setAccamulativeAmount(marketInfoData.electricity_balance));
    }
  }, [marketInfoData, dispatch]);

  useEffect(() => {
    if (marketInfoData == null) {
      refetchInfo();
    }
  }, [marketInfoData, refetchInfo]);

  // if (isInfoLoading) return "Loading...";

  const balance = marketInfoData?.electricity_balance || accamulativeAmount;

  const validatePriceInput = (value) => {
    value = value.replace(",", ".");
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) {
      setPrice(value);
    }
  };

  const validateAmountInput = (value) => {
    value = value.replace(",", ".");
    if (value === "" || (!isNaN(value) && Number(value) <= Number(balance))) {
      setAmount(value);
    }
  };

  const clickHandler = (e) => {
    e.preventDefault();

    toggleModal();
    setModalData({ balance, amount, price, gasFee, total: amount });

  };

  const isButtonDisabled = amount <= 0 || price <= 0;

  return (
    <div className={s.block}>
      <div className={s.topBar}>
        <h2 className={s.title}>Create Order</h2>
        <div className={s.backgroundTrapezoid}>
          <img src={titleBg} alt="title bg" width="222" height="40" />
        </div>
      </div>
      <div className={s.content}>
        <form className={s.orderForm}>
          {true ? (
            <>
              <div className={s.textInput}>
                <input
                  pattern="\d*"
                  inputMode="decimal"
                  name="amount"
                  placeholder="Enter amount kW"
                  value={amount}
                  onChange={(e) => validateAmountInput(e.target.value)}
                  id="amount"
                />
                <svg
                  width="279"
                  height="30"
                  viewBox="0 0 279 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 15C0 6.71573 6.71573 0 15 0H264C272.284 0 279 6.71573 279 15V15C279 23.2843 272.284 30 264 30H139.5H15C6.71573 30 0 23.2843 0 15V15Z"
                    fill="#0B0C0D"
                    fill-opacity="0.3"
                  />
                </svg>
              </div>

              <div className={s.price}>
                <div className={s.gasFee}>
                  {gasFee} <span>{"ton/kw"}</span>
                </div>
                <div className={s.textInput}>
                  <input
                    type="text"
                    name="price"
                    inputMode="decimal"
                    pattern="^\d*\.?\d*$"
                    placeholder="Enter price"
                    value={price}
                    onChange={(e) => validatePriceInput(e.target.value)}
                    id="price"
                  />
                  <svg
                    width="279"
                    height="30"
                    viewBox="0 0 279 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 15C0 6.71573 6.71573 0 15 0H264C272.284 0 279 6.71573 279 15V15C279 23.2843 272.284 30 264 30H139.5H15C6.71573 30 0 23.2843 0 15V15Z"
                      fill="#0B0C0D"
                      fill-opacity="0.3"
                    />
                  </svg>
                </div>
              </div>
            </>
          ) : (
            <div className={s.commingSoon}>{"coming soon..."}</div>
          )}

          <button
            className={s.orderButton}
            onClick={clickHandler}
            disabled={isButtonDisabled}
          >
            {"Sell"}
          </button>
        </form>
      </div>
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        data={modalData}
        type={"sell"}
        refetchInfo={refetchInfo}
      />
    </div>
  );
};

export default Order;
