import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ton from "../icons/ton.svg";
import kw from "../icons/kw.svg";
import { useTonConnectUI, useTonAddress, TonConnectButton } from '@tonconnect/ui-react';
import { postOffer, confirmTransaction, processTransaction, unlockTransaction } from "../../../http";
import { useSelector, useDispatch } from "react-redux";
import { queryClient } from "../../../QueryCLient";
import s from "./Modal.module.scss";
import axios from "axios";
import { beginCell } from '@ton/ton'


const BuyModal = ({ data, toggleModal }) => {
  const [tonConnectUI] = useTonConnectUI();
  const rawAddress = useTonAddress(false);
  const walletsList = tonConnectUI.getWallets();

  console.log("walletsList")
  console.log(walletsList)
  const [showTonConnectButton, setShowTonConnectButton] = useState(false);

  let USER_ID = null

  if (window.Telegram && window.Telegram.WebApp) {
    window.Telegram.WebApp.ready();

    if (window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      USER_ID = window.Telegram.WebApp.initDataUnsafe.user.id;
    } else {
      console.error('Telegram user data is not available.');
    }
  }
  else {
    console.error('Telegram WebApp is not initialized.');
  }


  const botToken = '7085702192:AAEJK-Ve6LuLKMYtZj8sE7x6L1AV0FiCzy4'
  // async function sendMessage(message) {
  //   const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

  //   try {
  //     const response = await axios.post(url, {
  //       chat_id: "5928954497",
  //       text: message
  //     });

  //     console.log('Message sent successfully:', response.data);
  //   } catch (error) {
  //     console.error('Error sending message:', error);
  //   }
  // }

  const handleConfirm = async () => {
    if (!rawAddress) {
      setShowTonConnectButton(true);
    } else {

      const dicPayload = [data.id, Math.floor(Date.now() / 1000), USER_ID];
      const stringPayload = JSON.stringify(dicPayload);
      const encodedPayload = btoa(stringPayload);
      console.log(encodedPayload);

      const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail(stringPayload)
        .endCell();
      const bodyFee = beginCell()
        .storeUint(0, 32)
        .storeStringTail("it`s a fee")
        .endCell();

      console.log(body)
      console.log(body.toBoc().toString("base64"))
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 100,
        messages: [
          {
            address: "0:0e050da47addb5746f2b8b2ee1494acde6bf03a761bb866e05f4f84adf9e957a",
            amount: data.price * 5e7,
            payload: bodyFee.toBoc().toString("base64")
          }
          ,
          {
            address: data.creator_address,
            amount: data.price * 95e7,
            payload: body.toBoc().toString("base64")
          }
        ]
      };



      try {
        const transactionBOC = btoa(transaction);
        console.log(transactionBOC)

        const response = await processTransaction(data.id, rawAddress);
        if (response.details === "Success") {
          console.log(response.transaction_created_id)

          try {
            const txResponse = await tonConnectUI.sendTransaction(transaction);
            console.log(JSON.stringify(txResponse))

            // sendMessage(`Transaction Response: ${JSON.stringify(txResponse)}\nAddress: ${data.creator_address}\nOffer: ${data.id}`);
            await confirmTransaction(data.id, txResponse.boc, "[" + dicPayload.toString() + "]", data.creator_address);
            toggleModal();

          } catch (error) {
            // sendMessage(`Error: Transaction cannot be processed\nAddress: ${data.creator_address}\nOffer: ${data.id}\nError: ${error}`);

            // unlockTransaction(data.id, response.transaction_created_id)
            console.error('Error during transaction:', error);
          }
        } else {
          // sendMessage(`Error: Transacti++on cannot be processed\nAddress: ${data.creator_address}\nOffer: ${data.id}\nResponse: ${response.detail}`);
          // unlockTransaction(data.id, response.transaction_created_id)
          console.error('Transaction error:', response.detail);
        }
      } catch (error) {
        // sendMessage(`Error during transaction\nAddress: ${data.creator_address}\nOffer: ${data.id}\nError: ${error}`);
        console.error('Error during transaction:', error);
      }
    }
  };

  return (
    <>
      <div className={s.modalTopBar} >
        <h2 className={s.modalTitle}>{"Buy"}</h2>
      </div>
      <div className={s.modalContent} style={{ width: "100%" }}>
        <div className={s.fieldBlock}>
          <div className={s.label}>
            {"Amount"}
            <img src={kw} alt="Amount" />
          </div>
          <div className={s.amount}>{data.amount}</div>
        </div>

        <div className={s.correlation}>
          <div className={s.fieldBlock}>
            <div className={s.label}>
              {"Price"}
              <img src={ton} alt="Price" />
            </div>
            <div className={s.price}>{data.price}</div>
          </div>

          <div className={s.fieldBlock}>
            <div className={s.label}>
              {"Gas Fee"}
              <img src={ton} alt="Gas" />
            </div>
            <div className={s.gasFee}>{(data.price * 0.05).toFixed(4)}</div>
          </div>
        </div>

        {/* <div className={s.fieldBlock}>
          <div className={s.labelBlock}>
            <div className={s.label}>
              {"Total"}
              <img src={ton} alt="Total" />
            </div>
            <div className={s.availability}>
              {"Your TON"}
              <span>{data.available}</span>
            </div>
          </div>

          <div className={s.total}>{data.total}</div>
        </div> */}
        {showTonConnectButton ?
          <button className={s.walletBtn} >
            <TonConnectButton
              style={{ background: "transparent", color: "transparent" }}
            ></TonConnectButton>
            <div className={s.customBorder} >
              Connect a wallet
            </div>
          </button> :
          <button className={s.confirmButton} onClick={handleConfirm}>{"Confirm"}</button>

        }
      </div>
      <div className={s.warning}>*You have only 10 seconds to confirm transaction</div>
    </>
  );
};
const ErrorModal = ({ errorText }) => {
  return (
    <>
      <div className={s.modalTopBar}>
        <div className={s.errorContainer}>
          <div className={s.modalTitle}>{"Verify your wallet, make at least one transaction from this wallet"}</div>
        </div>

      </div>
    </>
  );
};


const SellModal = ({ data, toggleModal }) => {
  const [showTonConnectButton, setShowTonConnectButton] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const rawAddress = useTonAddress(false);

  const sellkW = async () => {

    try {

      const response = await postOffer(data.amount, data.price, rawAddress);
      if (response.detail !== "Success") {
        setError(true);
        setErrorText(response.detail)
        return;
      }
      queryClient.invalidateQueries({ queryKey: ['offers'] });
      queryClient.invalidateQueries({ queryKey: ['marketInfo'] });
      toggleModal();
    } catch (error) {
      console.error('Error during offer posting:', error);
      setError(true);
    }

  };

  if (error) {
    return <ErrorModal errorText={errorText} />;
  }

  return (
    <>
      <div className={s.modalTopBar}>
        <h2 className={s.modalTitle}>{"Sell"}</h2>
      </div>
      <div className={s.modalContent}>
        <div className={s.values}>
          {" "}
          <div className={s.fieldBlock}>
            <div className={s.label}>
              {"Amount"}
              <img src={kw} alt="Amount" />
            </div>
            <div className={s.amount}>{data.amount}</div>
          </div>
          <div className={s.correlation}>
            <div className={s.fieldBlock}>
              <div className={s.label}>
                {"Price"}
                <img src={ton} alt="Price" />
              </div>
              <div className={s.price}>{data.price}</div>
            </div>

            <div className={s.fieldBlock}>
              <div className={s.label}>
                {"Gas Fee"}
                <img src={ton} alt="Gas" />
              </div>
              <div className={s.gasFee}>{(data.price * 0.05).toFixed(4)}</div>
            </div>
          </div>
          <div className={s.fieldBlock}>
            <div className={s.labelBlock}>
              <div className={s.label}>
                {"Balance After Confirmation"}
                <img src={kw} alt="Total" />
              </div>
              <div className={s.availability}></div>
            </div>

            <div className={s.total}>{data.balance - data.amount}</div>
          </div>
        </div>


        {showTonConnectButton ?
          <button className={s.walletBtn} >
            <TonConnectButton
              style={{ background: "transparent", color: "transparent" }}
            ></TonConnectButton>
            <div className={s.customBorder} >
              Connect a wallet
            </div>
          </button> :
          <button className={s.confirmButton} onClick={() => sellkW()} >{"Confirm"}</button>

        }


      </div>
    </>
  );
};

const Modal = ({ type, data, isOpen, toggleModal, refetchInfo }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleModal();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, toggleModal]);

  return isOpen
    ? ReactDOM.createPortal(
      <div className={s.modalOverlay}>
        <div className={s.modal} ref={modalRef}>
          <button className={s.closeBtn} onClick={toggleModal}>
            <svg
              width="34"
              height="35"
              viewBox="0 0 34 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17.5"
                r="11.2708"
                transform="rotate(135 17 17.5)"
                stroke="#3BB2E5"
                stroke-width="1.5"
              />
              <g filter="url(#filter0_d_234_7356)">
                <path
                  d="M21.5913 22.0912L16.9945 17.4944M12.3977 12.8977L16.9945 17.4944M16.9945 17.4944L12.3977 22.0912M16.9945 17.4944L21.5913 12.8977"
                  stroke="#3BB2E5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_234_7356"
                  x="9.64771"
                  y="10.1476"
                  width="14.6935"
                  height="14.6936"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.231373 0 0 0 0 0.698039 0 0 0 0 0.898039 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_234_7356"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_234_7356"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </button>
          {type === "buy" ? (
            <BuyModal data={data} toggleModal={toggleModal} />
          ) : type === "sell" ? (
            <SellModal data={data} refetchInfo={refetchInfo} toggleModal={toggleModal} />
          ) : (
            <ErrorModal />
          )}
        </div>
      </div>,
      document.body
    )
    : null;
};

export default Modal;
