import React from "react";
import s from "./Card.module.scss";
import separator from "../../icons/arrow.svg";
import avatar from "./avatar.png";

const truncateToValue = (value, maxLength = 10) => {
  if (value.length > maxLength) {
    return value.slice(0, maxLength) + "...";
  }
  return value;
};

const BuyCard = ({ title, fromValue, toValue, name, imageSrc, onclick }) => {
  return (
    <div className={s.card} onClick={onclick}>
      <div className={s.info}>
        <div className={s.title}>{title}</div>
        <div className={s.description}>
          <div className={s.fromValue}>{fromValue} kW</div>
          <div className={s.separator}>
            <img src={separator} alt="separator" />
          </div>
          <div className={s.name}>{name}</div>
          <div className={s.toValue}>{truncateToValue(toValue)} </div>
        </div>
        <div className={s.imageBg}>
          <img src={imageSrc} alt="product" />
          <div className={s.gradient}></div>
        </div>
      </div>
    </div>
  );
};

const MainCard = ({ title, fromValue, toValue, imageSrc, onclick }) => {
  return (
    <div className={s.card} onClick={onclick}>
      <div className={s.info}>
        <div className={s.title}>{title}</div>
        <div className={s.description}>
          <div className={s.fromValue}>{fromValue} kW</div>
          <div className={s.separator}>
            <img src={separator} alt="" />
          </div>
          <div className={s.toValue}>{toValue} lvl</div>
        </div>
      </div>
      <div className={s.image}>
        <img src={imageSrc || avatar} alt="" />
        <div className={s.gradient}></div>
      </div>
    </div>
  );
};

const Card = (props) => {

  return (
    (props.type === "station_type" && <BuyCard {...props} />) ||
    (props.type === "level" && <MainCard {...props} />)
  );
};

export default Card;
