import React from "react";
import s from "./Button.module.scss";
import back from "../../icons/back.svg";

const BackButton = ({ onclick }) => {

  return (
    <>
      <button className={s.backBtn} onClick={onclick}>
        <div className={s.customBorder}>
          <img src={back} alt="back" />
        </div>
      </button>
    </>
  );
};

export default BackButton;
