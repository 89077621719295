import React, { useEffect } from "react";
import s from "./Balance.module.scss";
import RepairButton from "./Button/Repair";

import titleBg from "./title_bg.svg";
import bg from "./bg.png";
import BackButton from "./Button/Back";
import TonBalance from "./Button/TonBalance";
import CopyLink from "./Button/CopyLink";
import { useNavigate } from "react-router-dom";
import { getReferralsCount } from "../../../http";
import WalletButton from "../../Shop/Button/Wallet";
export const Balance = ({ count }) => {

  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
    console.log("Back button pressed");
  }

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleBack);
    }

    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.offClick(handleBack);
      }
    };
  }, [navigate]);
  const walletButtonColors = {
    background: "#C6E7FF",
    // border: "#3BB2E5",
    shadow: "rgba(59, 178, 229, 0.3)",
  };
  return (
    <div className={s.block}>
      <div className={s.backBtn}>
        <BackButton onclick={handleBack} />
      </div>

      <div className={s.walletBtn}>
        <WalletButton colors={walletButtonColors} />
      </div>

      <div className={s.topBar}>
        <div className={s.backgroundTrapezoid}>
          <h1 className={s.title}>Your Friends</h1>
          <img src={titleBg} alt="title bg" width="222" height="40" />
        </div>
      </div>

      <div className={s.bottomBar}>
        <div className={s.count}>{count}</div>
        {/* <RepairButton /> */}

        <CopyLink />
      </div>
    </div>
  );
};
