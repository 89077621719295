import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const FooterButtons = [
    {
        name: "POWER",
        icon: {
            green: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/equip_green.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/equip_selected_green.svg"
            },
            blue: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/power_blue.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/power_selected_blue.svg"
            },
            yellow: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/power_yellow.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/power_selected_yellow.svg"
            }
        },
        path: "/power",
        isSelected: false
    },
    {
        name: "BOOST",
        icon: {
            green: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/boost_green.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/boost_selected_green.svg"
            },
            blue: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/boost_blue.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/boost_selected_blue.svg"
            },
            yellow: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/boost_yellow.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/boost_selected_yellow.svg"
            }
        },
        isSelected: false,
        comingSoon: true

    },
    {
        name: "MARKET",
        icon: {
            green: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/market_green.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/market_selected_green.svg"
            },
            blue: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/market_blue.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/market_selected_blue.svg"
            },
            yellow: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/market_yellow.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/market_selected_yellow.svg"
            }
        },
        isSelected: false,
        path: "/market",

        // comingSoon: true

    },
    {
        name: "FRIENDS",
        icon: {
            green: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/friends_green.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/friends_selected_green.svg"
            },
            blue: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/friends_selected_blue.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/friends_blue.svg"
            },
            yellow: {
                default: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/friends_yellow.svg",
                selected: "https://powerstations-bucket.s3.eu-north-1.amazonaws.com/navigation/friends_selected_yellow.svg"
            }
        },
        path: "/friends",

        isSelected: false,
        // comingSoon: true
    }
];

const colorSchemes = {
    green: {
        border: "#3BE5A8",
        background: "#182025",
        text: "#BBF3E2",
        shadow: "rgba(59, 229, 188, 0.3) 0px 0px 5px 0px",
        text_bg: "#1C4A47"

    },
    blue: {
        border: "#418EB9",
        background: "#182025",
        text: "#BBDDF3",
        shadow: "rgb(65, 142, 185) 0px 0px 5px 0px",
        text_bg: "#1C384A"


    },
    yellow: {
        border: "#B9AD41",
        background: "#252218",
        text: "#FFF6C6",
        shadow: "rgb(185, 173, 65) 0px 0px 5px 0px",
        text_bg: "#4A431C"
    }
};

function FooterBtn({ name, icon, path, style, isSelected, colors, comingSoon }) {
    const navigate = useNavigate();
    const handleClick = () => {
        if (path) {
            navigate(path);
        } else {
            console.log(`${name} clicked`);
        }
    };

    return (
        <div className={`FooterBtn FooterBtn-${style} ${isSelected ? 'selected' : ''}`} onClick={handleClick} style={{ borderColor: colors.border, background: colors.background, boxShadow: colors.shadow }}>
            <div className='text_container_info' >
                {comingSoon ?
                    <div className='coming_soon_container'>
                        <div className='text_coming_glow'></div>
                        <div className='text_coming' style={{ color: colors.text }}>COMING SOON</div>
                    </div>
                    :
                    <img src={isSelected ? icon.selected : icon.default} alt={name + " icon"} />
                }
                <div className='text' style={{ color: colors.text, background: colors.text_bg }}>{name}</div>
            </div>
        </div>
    );
}
const getColorScheme = (path) => {
    switch (path) {
        case 'energizer':
            return 'blue';
        case 'miner':
        case 'game':
            return 'green';
        default:
            return 'yellow';
    }
};

function Footer() {
    const location = useLocation();
    const [show, setShow] = useState("");

    useEffect(() => {
        setShow(location.pathname.slice(1));
    }, [location.pathname]);

    const colorSchemeKey = getColorScheme(location.pathname.slice(1));
    const colors = colorSchemes[colorSchemeKey];

    return (
        <div className='footer_container p10_5'>
            {FooterButtons.map((item, index) => {
                const isSelected = (location.pathname === '/miner' || location.pathname === '/game') && item.name === "POWER";
                const icon = item.icon[colorSchemeKey] || item.icon.default;

                return (
                    <FooterBtn
                        key={index}
                        name={isSelected ? "EQUIP" : item.name}
                        icon={icon}
                        style={colorSchemeKey}
                        path={isSelected ? "/game" : item.path}
                        isSelected={isSelected}
                        colors={colors}
                        comingSoon={item.comingSoon}
                    />
                );
            })}
        </div>
    );
}

export default Footer;


