import { createStore, combineReducers } from 'redux';
import electricityReducer from './reducer';
import reducerElectrisityCount from './ElectrisityCount/reducerElectrisityCount';
import AccamulativeAmountReduser from './AccamulativeAmount/reducerAccamulativeAmount';
const rootReducer = combineReducers({
    electricity: electricityReducer,
    electricityCount: reducerElectrisityCount,
    accamulativeAmount:AccamulativeAmountReduser
});

const store = createStore(rootReducer);

export default store;