import React, { useEffect, useState } from "react";
import s from "./filter.module.scss";

const Filter = ({ setFilterQuery }) => {
    const [filter, setFilter] = useState("all");

    useEffect(() => {
        console.log(filter);
        setFilterQuery(filter);
    }, [filter]);

    return (
        <div className={s.block}>
            <div
                className={`${s.parametr} ${filter === "day" ? s.selected : ""}`}
                onClick={() => setFilter("day")}
            >
                Day
            </div>
            <div
                className={`${s.parametr} ${filter === "week" ? s.selected : ""}`}
                onClick={() => setFilter("week")}
            >
                Week
            </div>
            <div
                className={`${s.parametr} ${filter === "all" ? s.selected : ""}`}
                onClick={() => setFilter("all")}
            >
                All
            </div>
        </div>
    );
};

export default Filter;
