import React, { useEffect } from 'react'
import Header from '../UI/Header'
import Footer from '../UI/Footer'
import "./investor.scss"
function Investor() {

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram.WebApp.BackButton && window.Telegram.WebApp.BackButton.isVisible) {
                window.Telegram.WebApp.BackButton.hide();
            }
        }
    }, []);
    return (
        <div className='investor'>
            <Header />
            <div className='coming_soon p10_5'>COMING SOON...</div>
            <Footer />
        </div>
    )
}

export default Investor