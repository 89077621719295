import React, { useState, useEffect, useRef } from "react";
import s from "./P2p.module.scss";
import Modal from "../Modal";
import { getOffers, getHistory } from "../../../http";
import { useInfiniteQuery } from "@tanstack/react-query";
import close from "./Vector 3.png";
import { closeOffer } from "../../../http";
import { useTonConnectUI } from "@tonconnect/ui-react";
import manage_search from "./manage_search.png"
import update from "./update.png"
import closeHistory from "./close.png"
import copy from "./Copy.png"
import { queryClient } from "../../../QueryCLient";


const P2p = ({ filterQuery, history, setHistory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [tonConnectUI] = useTonConnectUI();
  const observerRef = useRef();
  const containerRef = useRef(null);

  const handleClose = async (id) => {
    const transaction = {
      validUntil: Math.floor(new Date() / 1000) + 360,
      messages: [
        {
          address: "0:0e050da47addb5746f2b8b2ee1494acde6bf03a761bb866e05f4f84adf9e957a",
          amount: 1e8,
        }
      ]
    };
    try {
      const txResponse = await tonConnectUI.sendTransaction(transaction);
      console.log('Transaction Response:', txResponse);
      await closeOffer(id);
      refetch();
    } catch (error) {
      console.error('Error during transaction:', error);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const fetchOffers = async ({ pageParam = 1 }) => {
    const limit = 10;
    const offset = (pageParam - 1) * limit;
    const apiUrl = history ? getHistory : getOffers;
    const queryStr = `${filterQuery}&offset=${offset}&limit=${limit}`;

    try {
      const response = await apiUrl(queryStr);
      return response || { offers: [] };
    } catch (error) {
      console.error(`Error fetching ${history ? "history" : "offers"}:`, error);
      return { offers: [] };
    }
  };


  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['offers', filterQuery],
    queryFn: fetchOffers,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.offers && lastPage.offers.length > 0) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    const preventDefault = (e) => {
      if (containerRef.current && containerRef.current.contains(e.target)) {
        return;
      }
      e.preventDefault();
    };

    document.addEventListener("touchmove", preventDefault, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventDefault);
    };
  }, []);

  function formatNumber(input) {
    let num = Number(input);

    let str = num.toFixed(4);

    return str.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
  }

  const formatText = (str) => {
    if (str.length > 4) {
      return str[0] + "..." + str.slice(-3);
    }
    return str;
  };

  const formatAddress = (str) => {
    if (str.length > 20) {
      return str.slice(0, 10) + "..." + str.slice(-10);
    }
    return str;
  };

  const [copiedId, setCopiedId] = useState(null);
  const [copiedCommentId, setCopiedCommentId] = useState(null);

  const copyToClipboard = (text, id) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopiedId(id);
        setTimeout(() => {
          setCopiedId(null);
        }, 1000);
      })
      .catch(err => {
        console.error('Не удалось скопировать текст: ', err);
      });
  };

  const copyCommentToClipboard = (text, id) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopiedCommentId(id);
        setTimeout(() => {
          setCopiedCommentId(null);
        }, 1000);
      })
      .catch(err => {
        console.error('Не удалось скопировать текст: ', err);
      });
  };



  return (
    <div className={s.block}>
      <div className={s.content}>

        <div className={s.orderTable}>
          <div className={s.tableHeader}>
            {!history && <div className={s.buyerHeader}>{"Seller"}</div>}
            <div className={s.amountHeader}>{"Amount"}</div>
            <div className={s.rateHeader}>{"TON/kW"}</div>

            <div className={s.priceHeader}>{"Price"}</div>
            {history && <div className={s.buyerHeader}>{"Status"}</div>}
            <div className={s.empty} >
              {history ? <img src={closeHistory} onClick={() => {
                setHistory(false); window.location.reload();
              }}></img> : <img onClick={() => { setHistory(true); }} src={manage_search}></img>}

            </div>
          </div>

          {data && <div className={s.tableContent} ref={containerRef}>
            {data.pages.map((page, pageIndex) =>
              page.offers.map((item, index) => (
                <div className={s.container}>
                  <div className={s.tableCard} key={`${pageIndex}-${index}`}>
                    <span className={index % 2 == 1 ? s.info : s.black}>
                      <div>
                        {!history &&
                          <div className={s.buyer}>
                            {formatText(item.creator_address)}
                          </div>
                        }

                        <div className={s.amount}>{formatNumber(item.amount)} kW</div>
                        <div className={s.price}>{formatNumber(item.pricing)} TON</div>
                        <div className={s.price}>{formatNumber(item.price)} TON</div>
                        {item.status == "completed" && <div className={s.status}>Success</div>}
                        {item.status == "in process" && <div className={s.status}>Pending</div>}
                      </div>

                      {item.status == "in process" ?
                        <div className={s.pendingContainer}>
                          <div className={index % 2 == 0 ? s.statusContainer : s.statusContainerBlack}>
                            <div className={s.status}>Status:</div>
                            <div className={s.pending}>Pending...</div>
                          </div>
                          <div className={index % 2 === 0 ? `${s.time} ${s.light}` : `${s.time} ${s.dark}`}>Time</div>

                        </div> : ""}
                      {item.status == "completed" ?
                        <div className={s.pendingContainer}>
                          <div className={index % 2 == 0 ? s.statusContainer : s.statusContainerBlack}>
                            <div className={s.status}>Seller:</div>
                            <div onClick={() => copyToClipboard(item.offer_creator_address, item.id)} className={s.pending}>
                              {copiedId === item.id ? "Copied!" : formatAddress(item.offer_creator_address)}
                              <img src={copy} alt="Copy" />
                            </div>
                          </div>
                          <div className={index % 2 === 0 ? `${s.time} ${s.light}` : `${s.time} ${s.dark}`}>Time</div>
                        </div> : ""}
                    </span>
                    {
                      item.status !== "completed" ?
                        <button
                          className={
                            item.is_mine ?
                              (item.status === "in process" ? s.updateBtn : s.closeBtn) : (item.status === "in process" ? s.updateBtn : s.buyButton)
                          }
                          onClick={async () => {
                            if (item.is_mine) {
                              if (item.status !== "in process") {
                                handleClose(item.id);
                              }

                            } else {
                              toggleModal();
                              setModalData(item);
                            }
                          }}
                        >
                          {item.is_mine ? (
                            item.status === "in process" ? (
                              <div>
                                <img src={update} alt="update" />
                              </div>
                            ) : (
                              <div className={s.close}>
                                <img src={close} alt="close" />
                              </div>
                            )
                          ) : (

                            item.status === "in process" ? (
                              <div>
                                <img src={update} alt="update" />
                              </div>
                            ) : (
                              <div div className={s.customBorder}>
                                <span>Buy</span>
                              </div>
                            )
                          )}
                        </button>
                        :
                        <button className={s.comment} onClick={() => copyCommentToClipboard(item.comment, item.id)}>
                          {copiedCommentId === item.id ? "Copied!" : `${item.comment.slice(0, 3)} ...`}
                        </button>
                    }

                  </div>
                  <div className={s.divider}></div>

                </div>

              ))
            )}
            <div ref={observerRef} className={s.loading}>
              {isFetchingNextPage ? 'Loading more offers...' : 'No more offers to load.'}
            </div>
          </div>}

        </div >
      </div >
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        data={modalData}
        refetchInfo={refetch}
        type={"buy"}
      />
    </div >
  );
};

export default P2p;
