import React from "react";
import s from "./CardList.module.scss";
import Card from "./Card"

const CardList = ({ data }) => {

  return (
    <div className={s.cardList}>
      {data &&
        data?.map((card, index) => {
          return <Card key={card.id} {...card} />;
        })}
    </div>
  );
};

export default CardList;
