import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ton from "../icons/ton.svg";
import kw from "../icons/kw.svg";

import s from "./Modal.module.scss";
import { useQuery } from '@tanstack/react-query';
import { setElectricityData } from "../../Store/actions";
import { useSelector, useDispatch } from 'react-redux';
import { getInfo } from "../../../http";
import { transferElectricity } from "../../../http";
import { queryClient } from "../../../QueryCLient";
import { getElectricityCount } from "../../../http";
import { setElectricityCount } from "../../Store/ElectrisityCount/actionsElectrisityCount";
const AddEnergyModal = ({ toggleModal }) => {

  const dispatch = useDispatch();
  const electricityCount = useSelector(state => state.electricityCount.electricityCount);
  const [amount, setAmount] = useState(0);
  const [balanceAfter, setBalanceAfter] = useState(0);
  const [validationStatus, setValidationStatus] = useState({
    amount: true,
    balanceAfter: true
  });

  const {
    data: infoData,
    isLoading: isInfoLoading,
    error: infoError,
    refetch: refetchInfo,
  } = useQuery({
    queryKey: ['infoData'],
    queryFn: getElectricityCount,
    enabled: false,
  });

  useEffect(() => {
    if (electricityCount == null && infoData) {
      dispatch(setElectricityCount(infoData.electicity_count));
    }
  }, [electricityCount, infoData, dispatch]);

  useEffect(() => {
    if (electricityCount == null) {
      refetchInfo();
    }
  }, [electricityCount, refetchInfo]);

  useEffect(() => {
    if (electricityCount) {
      console.log(electricityCount)
      const newBalanceAfter = balance - amount;
      setBalanceAfter(newBalanceAfter < 0 ? electricityCount : newBalanceAfter);
      setValidationStatus((prev) => ({
        ...prev,
        balanceAfter: newBalanceAfter >= 0
      }));
    }
  }, [amount, electricityCount, refetchInfo]);


  const confirmHandler = async () => {
    if (!amount || !validationStatus.balanceAfter) {
      setValidationStatus((prev) => ({
        ...prev,
        amount: !!amount,
      }));
    }
    console.log(amount)
    await transferElectricity(amount)
    toggleModal();
    queryClient.invalidateQueries({ queryKey: ['minerData'] });
    refetchInfo()
  };
  if (isInfoLoading) return 'Loading...';

  const balance = infoData?.electicity_count || electricityCount;

  console.log(balance)
  console.log(amount)
  return (
    <>
      <div className={s.modalTopBar}>
        <h2 className={s.modalTitle}>{"Add Energy"}</h2>
      </div>
      <div className={s.modalContent}>
        <div className={s.values}>
          <div className={s.fieldBlock}>
            <div className={s.label}>
              {"Your Energizer balance"}
              <img src={kw} alt="Balance" />
            </div>
            <div className={s.balanceBefore}>{balance}</div>
          </div>
          <div className={s.fieldBlock}>
            <div className={s.label}>
              {"You want to send"}
              <img src={kw} alt="Balance" />
            </div>

            <div
              className={s.amount}
            >
              <input
                className={s.amount}
                pattern="^\d*\.?\d*$"
                inputMode="decimal"
                type="text"
                id="amount"
                placeholder={0}
                value={amount}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setAmount(value);
                  setValidationStatus((prev) => ({
                    ...prev,
                    amount: value > 0
                  }));
                }}
              />
            </div>
          </div>
          <div className={s.fieldBlock}>
            <div className={s.label}>
              {"Balance after sending"}
              <img src={kw} alt="Balance" />
            </div>
            <div className={s.balanceBefore}
              style={
                !validationStatus.balanceAfter ? { color: "red" } : {}
              }>
              {balanceAfter < 0 ? "" : balanceAfter}
            </div>
          </div>
        </div>

        <button className={s.confirmButton} onClick={confirmHandler}>
          {"Confirm"}
        </button>
      </div>
    </>
  );
};

const ClaimModal = ({ data }) => {

  return (
    <>
      <div className={s.modalTopBar}>
        <h2 className={s.modalTitle}>{"Claim"}</h2>
      </div>
      <div className={s.modalContent}>
        <div className={s.cominsoon}>{"COMING SOON"}</div>

        <button className={s.confirmButton}>{"Confirm"}</button>
      </div>
    </>
  );
};

const Modal = ({ type, data, isOpen, toggleModal }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleModal();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, toggleModal]);

  return isOpen
    ? ReactDOM.createPortal(
      <div className={s.modalOverlay}>
        <div className={s.modal} ref={modalRef}>
          <button className={s.closeBtn} onClick={toggleModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="35"
              viewBox="0 0 34 35"
              fill="none"
            >
              <circle
                cx="17"
                cy="17.5"
                r="11.2708"
                transform="rotate(135 17 17.5)"
                stroke="#3BE5A8"
                stroke-width="1.5"
              />
              <g filter="url(#filter0_d_286_8107)">
                <path
                  d="M21.5913 22.0912L16.9945 17.4944M12.3977 12.8976L16.9945 17.4944M16.9945 17.4944L12.3977 22.0912M16.9945 17.4944L21.5913 12.8976"
                  stroke="#3BE5A8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_286_8107"
                  x="9.64771"
                  y="10.1476"
                  width="14.6936"
                  height="14.6936"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.113725 0 0 0 0 0.615686 0 0 0 0 0.435294 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_286_8107"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_286_8107"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </button>
          {data.modalType === "add-energy" && <AddEnergyModal data={data} toggleModal={toggleModal} />}
          {data.modalType === "claim" && <ClaimModal data={data} />}
        </div>
      </div>,
      document.body
    )
    : null;
};

export default Modal;
