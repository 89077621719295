import React, { useEffect } from "react";
import s from "./Power.module.scss";
import { Balance } from "./Balance";
import Upgrade from "./Upgrade";
import { useSelector, useDispatch } from 'react-redux';
import { getUpdates, getInfo } from "../../http";
import { useQuery } from '@tanstack/react-query';
import { setElectricityData } from "../Store/actions";

function Power() {
  const dispatch = useDispatch();
  const electricityData = useSelector(state => state.electricity.electricityData);

  const { data: updatesData, isLoading: isLoadingUpdates, error: errorUpdates, refetch: refetchUpdates } = useQuery({
    queryKey: ['updatesData'],
    queryFn: () => getUpdates(),
  });

  const { data: infoData, isLoading: isLoadingInfo, error: errorInfo, refetch: refetchInfo } = useQuery({
    queryKey: ['infoData'],
    queryFn: getInfo,
    enabled: !electricityData,
    onSuccess: (data) => {
      dispatch(setElectricityData(data));
    }
  });

  useEffect(() => {
    if (!electricityData && !isLoadingInfo && infoData) {
      dispatch(setElectricityData(infoData));
    }
  }, [electricityData, isLoadingInfo, infoData, dispatch]);

  if (isLoadingUpdates || (!electricityData && isLoadingInfo)) return 'Loading...';
  if (errorUpdates || errorInfo) return `An error has occurred: ${errorUpdates?.message || errorInfo?.message}`;

  const id = electricityData?.id || infoData?.id;

  return (
    <div className={s.power}>
      <div className={s.content}>
        <Balance />
        <Upgrade data={updatesData} id={id} refetch={refetchUpdates} />
      </div>
    </div>
  );
}

export default Power;
