import React from "react";
import s from "./Upgrade.module.scss";
import titleBg from "./title_bg.svg";
import Decimal from "decimal.js";
import CardList from "./Cards/CardList";
import { UpgradeStation } from "../../../http";
// import { UpdateLevel } from "../../../http";
import { HireEngineer } from "../../../http";
import { UpgradeGeneration } from "../../../http";
import { useDispatch } from "react-redux";
import { decreaseElectricityCount } from "../../Store/ElectrisityCount/actionsElectrisityCount";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from 'react-redux';
import { setElectricityCount } from "../../Store/ElectrisityCount/actionsElectrisityCount";
import { queryClient } from "../../../QueryCLient";
import { getElectricityCount } from "../../../http";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
const Upgrade = ({ data, id, refetch }) => {
  const dispatch = useDispatch();
  const electricityCount = useSelector(state => state.electricityCount.electricityCount);



  const handleUpgradeClick = async (cost, onClickFunction) => {
    if (electricityCount < cost) {
      return;
    }

    dispatch(decreaseElectricityCount(cost));

    await onClickFunction();
    await refetch();
  };


  const upgradeData = [
    {
      id: 1,
      title: "Upgrade your station",
      fromValue: data.station_upgrade.upgrade_price,
      toValue: data.station_upgrade.new_value,
      imageSrc: data.station_upgrade.image,
      onclick: () => handleUpgradeClick(data.station_upgrade.upgrade_price, () => UpgradeStation(id, data.station_upgrade.upgrade_type)),
      type: data.station_upgrade.upgrade_type
    },
    {
      id: 2,
      title: "Increase generation kW",
      fromValue: data.generation_upgrade.upgrade_price,
      toValue: data.generation_upgrade.new_value,
      imageSrc: data.generation_upgrade.image,
      onclick: () => handleUpgradeClick(data.generation_upgrade.upgrade_price, () => UpgradeGeneration(id)),
      type: data.generation_upgrade.upgrade_type
    },
    {
      id: 3,
      title: "Hire an engineer",
      fromValue: data.engineer_upgrade.upgrade_price,
      toValue: data.engineer_upgrade.level,
      imageSrc: "https://power-stations-bucket.s3.eu-north-1.amazonaws.com/Power+Stations+%2B+Upgrade/Hire+engineer/lvl1.jpg",
      onclick: () => handleUpgradeClick(data.engineer_upgrade.upgrade_price, () => HireEngineer(id)),
      type: data.engineer_upgrade.upgrade_type
    }
  ];

  return (
    <div className={s.block}>
      <div className={s.topBar}>
        <h2 className={s.title}>Upgrade</h2>

        <div className={s.backgroundTrapezoid}>
          <img src={titleBg} alt="title bg" width="222" height="40" />
        </div>
      </div>
      <CardList data={upgradeData} />
    </div>
  );
};

export default Upgrade;
