import React from "react";
import s from "./Card.module.scss";
import slot from "../../images/slot_full.jpg";
import BuyButton from "../../Button/Buy";
import { beginCell } from '@ton/ton'
import { useTonConnectUI } from '@tonconnect/ui-react';

const Card = ({
  name,
  power,
  mining,
  consumption,
  price,
  image,
  uniqness_level,
  uniqness,
  buy_link,
  sale_price,
  id
}) => {
  const [tonConnectUI] = useTonConnectUI();

  // const handleConfirm = async () => {
  //   const body = beginCell().storeUint(1, 32).storeUint(1, 64).storeUint(0, 4).endCell()

  //   console.log(body)
  //   console.log(body.toBoc().toString("base64"))
  //   const myTransaction = {
  //     validUntil: Math.floor(Date.now() / 1000) + 360,
  //     messages: [
  //       {
  //         address: "EQAGKlyJq1BJ0h-ACkt9fNH3OYpNZNhcg8GxVvLw6ESy2C2n",
  //         amount: 2e9 + 0.1 * 1e9,
  //         payload: body.toBoc().toString("base64")
  //       }
  //     ]
  //   }

  //   const txResponse = await tonConnectUI.sendTransaction(myTransaction);
  // }




  const gradientForStick = {
    1: "linear-gradient(0deg, rgb(255 255 255 / 21%) 20%, rgb(166 166 166) 100%)",
    2: "linear-gradient(0deg, rgba(29, 217, 37, 0.21) 20%, rgb(29, 217, 37) 100%)",
    3: "linear-gradient(0deg, rgba(0, 70, 249, 0.21) 20%, #0046F9 100%)",
    4: "linear-gradient(0deg, rgb(255 0 0 / 71%) 20%, rgb(255 0 0 / 78%) 100%)",
    5: "linear-gradient(0deg, rgb(61 36 105 / 66%) 20%, rgb(92 0 255) 100%)"
  };

  const format = (number) => {
    if (number >= 1e3) {
      return [(number / 1e3).toFixed(1), " GH/s"];
    } else {
      return [number, " MH/s"];
    }
  };

  return (
    <div className={s.card}>
      {buy_link == null ?
        (<div className={s.soldOutBack}></div>) :
        ""}
      <div className={s.image}>
        <img src={image || slot} alt="" />
        <div className={s.gradient}></div>
        <div
          className={s.stick}
          style={{
            background: gradientForStick[uniqness_level],
          }}
        >
          {uniqness}
        </div>
      </div>
      <div className={s.info}>
        <div className={s.title}>{name}</div>
        <div className={s.description}>
          <p className={s.descriptionItem}>
            Speed <span className={s.value}>{format(power)[0]}</span>
            <span className={s.unit}> {format(power)[1]}</span>
          </p>
          <p className={s.descriptionItem}>
            Mining <span className={s.value}>{parseFloat(mining).toFixed(1)}</span>
            <span className={s.unit}> tBTC/d</span>
          </p>
          <p className={s.descriptionItem}>
            Consumes <span className={s.value}>{consumption}</span>
            <span className={s.unit}> Kw/h</span>
          </p>
        </div>
      </div>
      <div className={s.priceBlock}>
        {sale_price !== price ? (
          <div className={s.price}>
            <p className={s.discount}>{price}</p>
            <span>{sale_price}</span>
          </div>
        ) : (
          <div className={s.price}>
            <span>{price}</span>
          </div>
        )}
        <div className={s.buyNowButton}>
          <BuyButton link={buy_link} price={price} id={id} />
        </div>
      </div>
    </div>
  );
};

export default Card;
