import React, { useState, useEffect } from 'react';
import Decimal from 'decimal.js';
import "./energizen.scss";
import header_island from "./Rectangle120.svg";
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import { getInfo, updateElectricityCount } from '../../http';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { setElectricityData } from '../Store/actions';
import { setElectricityCount } from '../Store/ElectrisityCount/actionsElectrisityCount';


function Energizen() {

    const dispatch = useDispatch();
    const { data, isLoading, error } = useQuery({
        queryKey: ['infoData'],
        queryFn: getInfo,
    });

    const [isScaled, setIsScaled] = useState(false);
    const [electricityCountValue, setElectricityCountValue] = useState(new Decimal(0));
    const [accumulatedIncrease, setAccumulatedIncrease] = useState(new Decimal(0));
    const [storage, setStorage] = useState(new Decimal(0));
    const [tapStrength, setTapStrength] = useState(new Decimal(0));
    const [capacity, setCapacity] = useState(new Decimal(0));
    const [canClick, setCanClick] = useState(true);

    useEffect(() => {
        if (data) {
            const electricityCount = new Decimal(data.balance);
            dispatch(setElectricityData({ ...data, electricity_count: electricityCount.toNumber() }));
            dispatch(setElectricityCount(electricityCount.toNumber()));
            setElectricityCountValue(electricityCount);
            setStorage(new Decimal(data.storage));
            setTapStrength(new Decimal(data.tap_strength));
            setCapacity(new Decimal(data.level.capacity));
        }
    }, [data, dispatch]);

    const { mutate } = useMutation({
        mutationFn: increase => updateElectricityCount(data.id, accumulatedIncrease.toFixed(3)),
        onSuccess: (updatedData) => {
            setStorage(new Decimal(updatedData.storage));
            console.log("storage: " + storage)
        }
    });

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram.WebApp.BackButton && window.Telegram.WebApp.BackButton.isVisible) {
                window.Telegram.WebApp.BackButton.hide();
            }
        }
    }, []);

    useEffect(() => {
        StorageWidth()
    }, [storage]);

    useEffect(() => {
        const interval = setInterval(() => {
            mutate(accumulatedIncrease);
            setAccumulatedIncrease(new Decimal(0));
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (window.Telegram.WebApp && !window.Telegram.WebApp.isExpanded) {
            window.Telegram.WebApp.expand();
        }
    }, []);

    if (isLoading) return 'Loading...';
    if (error) return `An error has occurred: ${error.message}`;

    const showIncome = (income, clientX, clientY) => {
        const level = parseInt(data.tap_level, 10);
        let formattedIncome;

        if (level <= 32) {
            formattedIncome = `+${income.toFixed(3)} kW`;
        } else if (level > 32 && level <= 50) {
            formattedIncome = `+${income.toFixed(2)} kW`;
        } else if (level > 50) {
            formattedIncome = `+${income.toFixed(1)} kW`;
        }

        const incomeElement = document.createElement('div');
        incomeElement.innerText = formattedIncome;
        incomeElement.className = 'income-animation';
        incomeElement.style.position = 'absolute';
        incomeElement.style.left = `${clientX}px`;
        incomeElement.style.top = `${clientY + 300}px`;
        document.body.appendChild(incomeElement);

        setTimeout(() => {
            incomeElement.style.top = `${clientY + 200}px`;
        }, 10);

        setTimeout(() => {
            incomeElement.style.opacity = "0";
        }, 250);

        setTimeout(() => {
            incomeElement.remove();
        }, 1000);
    };

    const handleImageClick = () => {
        if (!canClick) return;

        if (storage.lt(tapStrength)) {
            setCanClick(true);
            setElectricityCountValue(prev => prev.plus(storage));
            setAccumulatedIncrease(prev => prev.plus(storage));
            setStorage(prev => prev.minus(storage));
        }
        setIsScaled(true);
        setTimeout(() => {
            setIsScaled(false);
            setCanClick(true);
        }, 200);
        setCanClick(false);
        setTimeout(() => setCanClick(true), 10);
    };

    const handleTouchStart = (event) => {
        if (!canClick) return;

        Array.from(event.touches).forEach(touch => {
            const clientX = touch.clientX - 60;
            const clientY = touch.clientY;
            if (storage.gte(tapStrength)) {
                showIncome(tapStrength, clientX + window.scrollX, clientY - 256);
                setElectricityCountValue(prev => prev.plus(tapStrength));
                setAccumulatedIncrease(prev => prev.plus(tapStrength));
                setStorage(prev => prev.minus(tapStrength));
            }
        });

        setCanClick(false);
        setTimeout(() => setCanClick(true), 50);
    };

    function StorageWidth() {
        return `${storage.div(capacity).times(100).toFixed(0)}%`;
    }


    return (
        <div className='energizen'>
            <Header />
            <div className='current_count_cotainer p10_5'>
                <div className='current_count'>
                    {electricityCountValue.toFixed(2)} kW
                </div>
            </div>

            <div className='cliker_container p10_5'>
                <img
                    className={`img_clicker ${isScaled ? 'scale' : ''} ${!canClick || storage.gte(capacity) ? 'disabled' : ''}`}
                    src={data.level.image}
                    onClick={handleImageClick}
                    onTouchStart={handleTouchStart}
                    alt="Clicker"
                    style={{ cursor: canClick && storage.lt(capacity) ? 'pointer' : 'not-allowed' }}
                />
            </div>

            <div className='progress_info_con p10_5'>
                <div className='cerrent_info_container'>
                    <div className='termonuclear'>{data.station_type.name}</div>
                    <div className='level'>{data.level.value} level</div>
                </div>

                <div className="status-widget">
                    <div className='status'>
                        <div className="status-item">
                            <div className='label' style={{ color: "#D7F8FF" }}>Storage</div>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: StorageWidth() }}></div>
                                <div className='current_balance'>{data.level.capacity + " kW"}</div>
                            </div>
                            <div className="percentage" style={{ color: "#D7FFD7" }}>
                                {StorageWidth()}
                            </div>
                        </div>
                        <div className="status-item">
                            <div className='label' style={{ color: "#FFD0C6" }}>Power</div>
                            <div className="progress-bar wear">
                                <div className="progress" style={{ width: "100%" }}></div>
                            </div>
                            <span className="percentage" style={{ color: "#FFD0C6" }}>100%</span>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Energizen;
