import React from "react";
import s from "./Button.module.scss";
import "./ton.scss";
import wallet from "../icons/wallet.svg";
import { TonConnectButton } from "@tonconnect/ui-react";

const WalletButton = ({ colors }) => {
  const buttonStyle = {
    background: colors.background,
    border: `1px solid ${colors.border}`,
    boxShadow: `0 0 5px 0 ${colors.shadow}`,
  };

  const customBorderStyle = {
    background: colors.background,
    border: `1px solid ${colors.border}`,
  };

  return (
    <button className={s.walletBtn} style={buttonStyle}>
      <TonConnectButton
        style={{ background: "transparent", color: "transparent" }}
      ></TonConnectButton>
      <div className={s.customBorder} style={customBorderStyle}>
        <img src={wallet} alt="wallet" />
      </div>
    </button>
  );
};

export default WalletButton;
