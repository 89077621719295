import React, { useEffect, useState } from "react";
import s from "./Filter.module.scss";
import fiveHoney from "./500.png";
import threeHoney from "./3000.png";
import honeyHoney from "./10000.png";

const Filter = ({ setFilterQuery, history }) => {
  const [rangeMin, setRangeMin] = useState("");
  const [rangeMax, setRangeMax] = useState("");
  const [volume, setVolume] = useState(0);
  const [sortDirection, setSortDirection] = useState("ASC");
  const [orderBy, setOrderBy] = useState("Price");
  const [tradeType, setTradeType] = useState("Sell");
  const [filterBy, setFilterBy] = useState("Success");
  const [selectedPreset, setSelectedPreset] = useState(null);

  const updateFilterQuery = () => {
    const minQuery = rangeMin && `min=${rangeMin}`;
    const maxQuery = rangeMax && `max=${rangeMax}`;
    const orderByQuery = orderBy && `order=${sortDirection}`;
    const sortDirectionQuery = `orderBy=${orderBy}`;
    const minVolume = `min_volume=${volume}`;
    const tradeTypeQuery = `trade_type=${tradeType}`;
    const filterByQuery = history && `filter_by=${filterBy}`;

    let queryArray = [];

    if (history) {
      queryArray = [minQuery, maxQuery, tradeTypeQuery, filterByQuery, sortDirection].filter(Boolean);
    } else {
      queryArray = [minQuery, maxQuery, orderByQuery, sortDirectionQuery, minVolume].filter(Boolean);
    }

    const filterQueryString = queryArray.length ? `?${queryArray.join('&')}` : '';
    setFilterQuery(filterQueryString);
  };

  useEffect(() => {
    updateFilterQuery();
  }, [orderBy, sortDirection, volume, tradeType, filterBy, history, tradeType]);

  const onClickOrderBy = (e) => {
    e.preventDefault();
    if (history) {
      setFilterBy(prev => prev === "Success" ? "Pending" : "Success");
    } else {
      setOrderBy(prev => (prev === "Price" ? "Volume" : (prev === "Volume" ? "My" : "Price")));
    }
  };

  const onClickTradeType = (e) => {
    e.preventDefault();
    setTradeType(prev => prev === "Sell" ? "Buy" : "Sell");
  };

  const onClickSortDirection = (e) => {
    e.preventDefault();
    setSortDirection(prev => (prev === "ASC" ? "DESC" : "ASC"));
  };

  const onClickPreset = (value, preset) => {
    if (selectedPreset === preset) {
      setVolume(0);
      setSelectedPreset(null);
    } else {
      setVolume(value);
      setSelectedPreset(preset);
    }
    updateFilterQuery();
  };

  const validateInput = (value) => {
    value = value.replace(",", ".");
    const regex = /^\d*\.?\d*$/;
    return regex.test(value) ? value : "";
  };

  return (
    <div className={s.block}>
      <div className={s.content}>
        {history ? (
          <form className={s.orderForm}>
            <div className={s.toggleButtons}>
              <div className={`${s.tradeButton} ${s.buy} ${tradeType === 'Buy' ? s.active : ''}`} onClick={onClickTradeType}>Buy</div>
              <div className={`${s.tradeButton} ${s.sell} ${tradeType === 'Sell' ? s.active : ''}`} onClick={onClickTradeType}>Sell</div>
            </div>
            <div className={s.containerFilter}>
              <div className={s.orderBy}>
                <button className={s.orderByBtn} onClick={onClickOrderBy}>{filterBy}</button>
              </div>
              <button className={s.sortButton} onClick={onClickSortDirection}>
                <div className={s.desc}>
                  <svg
                    width="10"
                    height="13"
                    viewBox="0 0 10 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.9624 1.32328L9.80936 11.0038C10.1722 11.9168 9.13864 12.7509 8.32285 12.2035L5.59274 10.3715C5.25751 10.1465 4.8199 10.1452 4.48333 10.3681L1.68073 12.2244C0.862312 12.7665 -0.165741 11.9279 0.200886 11.0172L4.10545 1.31911C4.44259 0.481716 5.62903 0.484384 5.9624 1.32328Z"
                      fill="#C6E7FF"
                    />
                  </svg>
                </div>
                <div className={s.asc}>
                  <svg
                    width="10"
                    height="13"
                    viewBox="0 0 10 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.0376 11.6767L0.190639 1.99621C-0.172176 1.08322 0.861363 0.249111 1.67715 0.796533L4.40726 2.62852C4.74249 2.85347 5.18009 2.85479 5.51667 2.63186L8.31927 0.775568C9.13769 0.233492 10.1657 1.07213 9.79911 1.98275L5.89455 11.6809C5.55741 12.5183 4.37097 12.5156 4.0376 11.6767Z"
                      fill="#C6E7FF"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </form>
        ) : (
          <form className={s.orderForm}>
            <div className={s.range}>
              <input
                type="text"
                inputMode="decimal"
                pattern="^\d*\.?\d*$"
                name="rangeMin"
                placeholder="Min"
                value={rangeMin}
                onChange={(e) => setRangeMin(validateInput(e.target.value))}
                onBlur={updateFilterQuery}
              />
            </div>
            <div
              className={`${s.preSort} ${selectedPreset === 'fiveHoney' ? s.selected : ''}`}
              style={{ backgroundImage: `url(${fiveHoney})` }}
              onClick={() => onClickPreset(500, 'fiveHoney')}
            >
              <div>500</div>
            </div>
            <div
              className={`${s.preSort} ${selectedPreset === 'threeHoney' ? s.selected : ''}`}
              style={{ backgroundImage: `url(${threeHoney})` }}
              onClick={() => onClickPreset(3000, 'threeHoney')}
            >
              <div>3k</div>
            </div>
            <div
              className={`${s.preSort} ${selectedPreset === 'honeyHoney' ? s.selected : ''}`}
              style={{ backgroundImage: `url(${honeyHoney})` }}
              onClick={() => onClickPreset(10000, 'honeyHoney')}
            >
              <div>10k</div>
            </div>
            <div className={s.orderBy}>
              <button className={s.orderByBtn} onClick={onClickOrderBy}>{orderBy}</button>
            </div>
            <button className={s.sortButton} onClick={onClickSortDirection}>
              <div className={s.desc}>
                <svg
                  width="10"
                  height="13"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.9624 1.32328L9.80936 11.0038C10.1722 11.9168 9.13864 12.7509 8.32285 12.2035L5.59274 10.3715C5.25751 10.1465 4.8199 10.1452 4.48333 10.3681L1.68073 12.2244C0.862312 12.7665 -0.165741 11.9279 0.200886 11.0172L4.10545 1.31911C4.44259 0.481716 5.62903 0.484384 5.9624 1.32328Z"
                    fill="#C6E7FF"
                  />
                </svg>
              </div>
              <div className={s.asc}>
                <svg
                  width="10"
                  height="13"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.0376 11.6767L0.190639 1.99621C-0.172176 1.08322 0.861363 0.249111 1.67715 0.796533L4.40726 2.62852C4.74249 2.85347 5.18009 2.85479 5.51667 2.63186L8.31927 0.775568C9.13769 0.233492 10.1657 1.07213 9.79911 1.98275L5.89455 11.6809C5.55741 12.5183 4.37097 12.5156 4.0376 11.6767Z"
                    fill="#C6E7FF"
                  />
                </svg>
              </div>
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Filter;
