import React, { useState } from "react";
import Card from "./Card";
import Modal from "../Modal";

const CardList = ({ minerCards }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {minerCards.map((minerCard, index) => (
        <Card
          key={minerCard.id}
          cardData={minerCard}
          setModalData={setModalData}
          setIsOpen={setIsOpen}
        />
      ))}

      <Modal isOpen={isOpen} toggleModal={toggleModal} data={modalData} />
    </>
  );
};

export default CardList;
