import React from "react";
import s from "./Button.module.scss";

const RepairButton = () => {
  return (
    <>
      <button className={s.repairBtn}>Repair</button>
    </>
  );
};

export default RepairButton;
