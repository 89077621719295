import { SET_ACCAMULATIVE_AMOUNT, DECREASE_ACCAMULATIVE_AMOUNT, INCREASE_ACCAMULATIVE_AMOUNT} from './actionsAccamulativeAmount';

const initialState = {
  AccamulativeAmount: null,  
};

function AccamulativeAmountReduser(state = initialState, action) {
  switch (action.type) {
    case SET_ACCAMULATIVE_AMOUNT:
      return {
        ...state,
        electricityCount: action.payload,
      };
    case DECREASE_ACCAMULATIVE_AMOUNT:
      return {
        ...state,
        electricityCount: state.electricityCount - action.payload,
      };
    case INCREASE_ACCAMULATIVE_AMOUNT:
      return {
        ...state,
        electricityCount: state.electricityCount + action.payload,
      };
    default:
      return state;
  }
}

export default AccamulativeAmountReduser;
