export const SET_ELECTRICITY_COUNT = 'SET_ELECTRICITY_COUNT';
export const DECREASE_ELECTRICITY_COUNT = 'DECREASE_ELECTRICITY_COUNT';

export function setElectricityCount(value) {
  console.log("Setting electricity count:", value); 
  return {
    type: SET_ELECTRICITY_COUNT,
    payload: value,
  };
}

export function decreaseElectricityCount(amount) {
  return {
    type: DECREASE_ELECTRICITY_COUNT,
    payload: amount,
  };
}
