import React, { useEffect } from "react";
import s from "./Balance.module.scss";
import RepairButton from "./Button/Repair";
import InfoBar from "./InfoBar";
import titleBg from "./title_bg.svg";
import bg from "./bg.png";
import BackButton from "./Button/Back";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getElectricityCount } from "../../../http";
import { useQuery } from "@tanstack/react-query";
import { setElectricityCount } from "../../Store/ElectrisityCount/actionsElectrisityCount";

export const Balance = () => {
  const dispatch = useDispatch();
  const electricityCount = useSelector(state => state.electricityCount.electricityCount);
  const navigate = useNavigate();

  const {
    data: electricityCountData,
    isLoading: isElectricityCountLoading,
    error: electricityCountError,
  } = useQuery({
    queryKey: ['electricityCount'],
    queryFn: getElectricityCount,

  });
  useEffect(() => {
    if (electricityCountData) {
      dispatch(setElectricityCount(electricityCountData.electicity_count))
    }
  }, [electricityCountData, dispatch]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleBack);
    }

    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.offClick(handleBack);
      }
    };
  }, [navigate]);

  if (isElectricityCountLoading) return 'Loading...';
  if (electricityCountError) return `An error has occurred: ${electricityCountError.message}`;

  const balance = electricityCount ?? electricityCountData?.electricity_count;

  function handleBack() {
    navigate(-1);
  }

  return (
    <div className={s.block}>
      <div className={s.backBtn} onClick={handleBack}>
        <BackButton />
      </div>

      <div className={s.topBar}>
        <h1 className={s.title}>Your balance</h1>
        <p className={s.value}>{balance} kW</p>
        <div className={s.backgroundTrapezoid}>
          <img src={titleBg} alt="title bg" width="296" height="63" />
        </div>
      </div>
      <div className={s.bottomBar}>
        <InfoBar
          title={"Thermonuclear Reactor"}
          indicatorName={"Power"}
          indicatorValue={100}
        />
        <RepairButton />
      </div>
      <div className={s.bgImage}>
        <img src={bg} alt="bg" />
      </div>
    </div>
  );
};
