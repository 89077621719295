import { SET_ELECTRICITY_COUNT, DECREASE_ELECTRICITY_COUNT } from './actionsElectrisityCount';

const initialState = {
  electricityCount: null,  
};

function electricityCountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ELECTRICITY_COUNT:
      console.log("Reducer received SET_ELECTRICITY_COUNT:", action.payload); 
      return {
        ...state,
        electricityCount: action.payload,
      };
    case DECREASE_ELECTRICITY_COUNT:
      return {
        ...state,
        electricityCount: state.electricityCount - action.payload,
      };
    default:
      return state;
  }
}

export default electricityCountReducer;
