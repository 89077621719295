import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  QueryClientProvider,
} from '@tanstack/react-query'
import store from './Components/Store/store';
import { Provider } from 'react-redux';
import { queryClient } from './QueryCLient';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Authorization } from './http';
const root = ReactDOM.createRoot(document.getElementById('root'));


let platform = window.Telegram.WebApp.platform;



    // if (platform != 'ios'&& platform != 'android') {
    //        root.render(
    //             <div></div>
    //         ); 
    //     } else {
    //       root.render(

    //         <React.StrictMode>  
    //           <TonConnectUIProvider 
    //           manifestUrl="https://electrostations.pages.dev/manifest.json">
          
    //             <Provider store={store}>
    //               <QueryClientProvider client={queryClient}>
    //                   <App/> 
    //                   {/* <ReactQueryDevtools/> */}
    //               </QueryClientProvider>
    //           </Provider>
    //       </TonConnectUIProvider>
    //       </React.StrictMode> 
          
    //       );
    //  }

     
     root.render(

      <React.StrictMode>  
        <TonConnectUIProvider 
        manifestUrl="https://electrostations.pages.dev/manifest.json">
    
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App/> 
                {/* <ReactQueryDevtools/> */}
            </QueryClientProvider>
        </Provider>
    </TonConnectUIProvider>
    </React.StrictMode> 
    
    );

reportWebVitals();
